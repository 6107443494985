import mixpanel from 'mixpanel-browser';
import { trackingEvents } from './events';

mixpanel?.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  'trackAutomaticEvents': true,
  'autotrack': true,
  'api_host': process.env.REACT_APP_MIXPANEL_API_HOST
});

mixpanel.register({
  version: '2.0'
});

export { mixpanel, trackingEvents };

