import { Warning } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { submitDataInsufficiency, uploadFile } from '../../../Config/api';
import { UIStore } from '../../../Config/store';
import { useGetInsufficiencies } from '../../../Helper/hooks';
import { statesEnum } from '../../../Utils/enums';
import InsufficiencyCard from './card';
import CompletedInsufficiency from './completed';

// const Card = motion.MuiCard;
export default function Insufficiency({ subType, insufficiencyData, id = 22, insufficiencyDataList, responseList }) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const form = UIStore.useState((s) => s.form);
  const docData = UIStore.useState((s) => s.docData);

  const formInfo = UIStore.useState((s) => s.formInfo);
  const currentWorkingStepperStep = UIStore.useState((s) => s.currentWorkingStepperStep);
  const currentCredentialUnderFetch = form?.list?.[currentWorkingStepperStep];

  const [inputs, setInputs] = useState({

  });

  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState({});
  const [infoStatusPerRequestId, setInfoStatusPerRequestId] = useState({});
  const [historyOfAllRequestsId, setHistoryOfAllRequestsId] = useState({});
  // const [insufsFiltered, setInsufsFiltered] = useState([]);

  const { insufsFiltered, insufsResolvedFiltered } = useGetInsufficiencies(responseList, insufficiencyDataList);

  const [infoStatus, setInfoStatus] = useState({});
  const [infoToSubmit, setInfoToSubmit] = useState({
    insufficiencyDataList: [],
    documents: []
  });
  const searchedQuery = UIStore.useState((s) => s.searchedQuery) ?? {};
  useEffect(() => {
    if (insufsFiltered?.length > 0) {
      const insufs = insufsFiltered?.reduce((acc, s) => {
        acc[s.insufficiencyId] = {
          text: '',
          files: { 0: null },
          displayName: s.displayName
        };
        return acc;
      }, {});
      console.log('insufs', insufs);
      setInputs(insufs);
    }
  }, [insufsFiltered]);

  // useEffect(() => {
  //   const request = docData?.request;
  //   const response = docData?.responseList;
  //   const data = {};
  //   const originalDataEntered = {};
  //   const ids = {};
  //   const historyOfAllRequestsIdTemp = {};
  //   if (request?.length === 0 || !request) return;
  //   request?.forEach((element) => {
  //     const filtered = response?.filter((x) => x.requestId === element.id);
  //     console.log('status: filtered', filtered);
  //     const history = [];
  //     for (let j = 0; j <= filtered.length - 1; j++) {
  //       if (!element?.insufficiencyDataList?.[j]) {
  //         ids[element.id] = {};
  //         originalDataEntered[element.id] = element.data;
  //         data[element.id] = {
  //           query: filtered[j],
  //           // response: element?.insufficiencyDataList,
  //           done: false
  //           // done: element.state === 'INSUFFICIENT_DATA' ? false : true
  //         };
  //       } else {
  //         history.push({
  //           query: filtered[j],
  //           raisedOn: filtered[j].insufficiencyData[0].raisedOn,
  //           response: element?.insufficiencyDataList[j],
  //         });
  //       }
  //     }
  //     console.log('loop: ', history);
  //     historyOfAllRequestsIdTemp[element.id] = history;
  //   });

  //   setHistoryOfAllRequestsId(historyOfAllRequestsIdTemp);
  //   setOriginalDataEnteredList(originalDataEntered);
  //   setInfoStatusPerRequestId(ids);

  //   setInfoStatus(ids);
  //   setInfo(data);
  // }, []);
  // console.log('info status:', info, infoStatusPerRequestId, infoToSubmit, historyOfAllRequestsId);
  // console.log('insufficiencyData::', insufficiencyData);

  const checkIfAllInsufficiencyIsResolved = () => {
    console.log('checkIfAllInsufficiencyIsResolved::--', info, id, infoStatusPerRequestId, insufsFiltered);
    const temp = insufsFiltered?.filter((yu) => info?.[id]?.[yu.insufficiencyId]) || [];
    return temp?.length;
  };

  console.log('Insufficiency inputs:', insufsResolvedFiltered);
  return (

    <Grid
      container
      direction="column"
      justifyContent={'center'}
      alignItems="center"
      rowGap={1}
      rowSpacing={1}
      sx={{ marginTop: '1em' }}>

      {insufsResolvedFiltered?.insuf?.length > 0 ? <>
        <CompletedInsufficiency
          response={insufsResolvedFiltered} />
      </> : null}

      {(formInfo[currentCredentialUnderFetch?.key]?.state === statesEnum?.INSUFFICIENT_DATA ||
        formInfo[currentCredentialUnderFetch?.key]?.state === statesEnum?.ACTION_REQUIRED) ?
        <>
          {
            (!info[id] && insufsFiltered.length > 0) ? <>
              <Divider fullWidth sx={{ width: '100%' }}>
                <Typography
                  variant="body1" color="error" sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <Warning sx={{ fontSize: 20, marginRight: '5px' }} />
                  {' '}
                  Attention Required
                </Typography>
              </Divider>
            </> : null
          }

          {insufsFiltered?.map((insuf, i) => <>
            <InsufficiencyCard
              key={`insuf${i}-${insuf.insufficiencyId}`}
              bgv={currentCredentialUnderFetch?.key}
              item={insuf}
              // setInfo={setInfo}
              infoStatusPerRequestId={infoStatusPerRequestId[id]}
              setInfoStatusPerRequestId={(a) => {
                setInfoStatusPerRequestId({ ...infoStatusPerRequestId, [id]: a });
              }}
              setInfoToSubmit={setInfoToSubmit}
              field={inputs[insuf.insufficiencyId]?.text || ''}
              files={inputs[insuf.insufficiencyId]?.files || {}}
              setField={(e) => setInputs((xx) => {
                const temp = { ...xx };
                temp[insuf.insufficiencyId].text = e;
                return temp;
              })}
              setFiles={(e) => setInputs((xx) => {
                const temp = { ...xx };
                temp[insuf.insufficiencyId].files = e;
                return temp;
              })} />


          </>)}

          {insufsFiltered?.length > 0 && !info[id] ? <LoadingButton
            loading={loading[id]}
            variant="contained"
            disabled={!Object.keys(inputs).every((key) => (inputs[key]
                ?.text !== '' ||
              Object.values(inputs[key]?.files)?.filter((zz) => !zz).length <= 0))}
            sx={{ margin: '1em 0' }}
            datacy="submit"
            onClick={async () => {
              setLoading({ ...loading, [id]: true });
              let tempInfoToSubmit = { ...infoToSubmit };
              let tempInfoPerRequestId = { ...infoStatusPerRequestId[id] };
              for await (const key of Object.keys(inputs)) {
                try {
                  const filesArray = [];
                  let count = 0;
                  for await (const f of Object.values(inputs[key].files)) {
                    if (f) {
                      const link = await uploadFile(
                          f,
                          {
                            ...(currentCredentialUnderFetch?.key.includes('bgv-') ? {
                              folderName: currentCredentialUnderFetch?.key
                            } : {}),
                          // imageHasDocument: true,
                          // shortenUrl: true
                          }
                      );

                      if (link) {
                        filesArray.push({
                          'documentType': 'Other',
                          'fileType': 'Url',
                          'document': {
                            'label': `${key}::FILE::${count++}`,
                            'url': link
                          },
                          'fileName': f.name
                        });
                      } else {
                        throw new Error('Invalid file');
                      }
                    }
                  }
                  tempInfoToSubmit = {
                    insufficiencyDataList: [
                      ...tempInfoToSubmit?.insufficiencyDataList,
                      {
                        'insufficiencyId': key,
                        'value': inputs[key].text
                      }],
                    documents: [...tempInfoToSubmit?.documents, ...filesArray]
                  };

                  console.log('insuf:: tempInfoToSubmit::--', tempInfoToSubmit);
                  if (inputs[key].text || filesArray.length > 0) {
                    tempInfoPerRequestId = { ...tempInfoPerRequestId, [key]: true };
                  }
                } catch (e) {
                  console.log(e);
                  // window.snackbar('error', e.message);
                }
              }

              // setInfoStatusPerRequestId(tempInfoPerRequestId);
              console.log('insuf:: tempInfoToSubmit::', tempInfoToSubmit);
              await submitDataInsufficiency(
                  currentCredentialUnderFetch?.key,
                  searchedQuery?.orgId,
                  searchedQuery?.txnId,
                  subType,
                  {
                    ...tempInfoToSubmit
                  }
              ).then(async (qq) => {
                if (qq) {
                  const temp = { ...info, [id]: true };

                  setInfoToSubmit(tempInfoToSubmit);
                  setInfoStatusPerRequestId({ ...infoStatusPerRequestId, [id]: tempInfoPerRequestId });
                  setInfo(temp);
                  // const found = Object.values(temp ?? {})?.findIndex((qw) => !qw.done);
                  // setInfo(temp);
                  // if (found === -1) {
                  // navigate(-1);
                  // }

                  // const subData = await getDataOfCompletedForm(
                  //     currentCredentialUnderFetch?.key,
                  //     searchedQuery?.orgId, searchedQuery?.txnId
                  // );
                  // updateStore('docData', subData);
                }
              }).finally(() => {
                setLoading({ ...loading, [id]: false });
              });
            }}
          >
            Submit
          </LoadingButton> : null}
        </> :
        null}


    </Grid>
  );
}
