import { Grid } from '@mui/material';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomTextField from '../../../Components/UI/Input/customTextField';
import { UIStore } from '../../../Config/store';
import { trimFields } from '../../../Helper/helpers';
import { mixpanel, trackingEvents } from '../../../Monitoring/Mixpanel';
import { tawkToConfig } from '../../../Utils/enums';


const NameAsPerDocument = React.forwardRef((props, ref) => {
  const { fn = () => { }, loading = {} } = props;
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const currentUserData = UIStore.useState((s) => s.currentUserData);
  const [editedState, setEditedState] = useState({});

  const [info, setInfo] = useState(locationState?.preFilled ? locationState?.preFilled : {
    nameAsPerDocument: ''
  });


  const [errors, setErrors] = useState({
    nameAsPerDocument: ''
  });

  const handleChange = async (name, value, type) => {
    setEditedState((prev) => ({ ...prev, nameAsPerDocument: value }));
    if (errors.nameAsPerDocument) delete errors.nameAsPerDocument;
  };


  const handleSubmit = async () => {
    try {
      const tempErrors = { nameAsPerDocument: '' };
      const editedStateTrimmed = trimFields(editedState);
      if (editedStateTrimmed?.nameAsPerDocument === '' && found?.required) {
        tempErrors.nameAsPerDocument = `nameAsPerDocument is empty`;
        document.getElementById(`nameAsPerDocument`)?.scrollIntoView();
      }


      if (tempErrors.nameAsPerDocument?.length > 0) {
        setErrors(tempErrors);
        throw new Error('Errors found');
      }

      fn({
        ...editedStateTrimmed
      });

      setInfo(editedStateTrimmed);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const fetchInfo = async () => {
    const loadingTemp = { ...loading };
    if (loadingTemp?.editData?.nameAsPerDocument && loadingTemp?.editData?.nameAsPerDocument.length > 0) {
      let temp = { nameAsPerDocument: '' };
      if (loadingTemp?.editData?.nameAsPerDocument) {
        fn({
          nameAsPerDocument: loadingTemp?.editData?.nameAsPerDocument
        });
        temp = {
          ...info,
          nameAsPerDocument: loadingTemp?.editData?.nameAsPerDocument
        };
      }
      setInfo(temp);
      setEditedState(temp);
    } else {
      const temp = {
        ...info,
        nameAsPerDocument: currentUserData?.profileDetails?.name,
        ...loadingTemp?.editData
      };
      fn({
        nameAsPerDocument: currentUserData?.profileDetails?.name,
        ...loadingTemp?.editData
      });
      setInfo(temp);
      setEditedState(temp);
    }
  };

  useImperativeHandle(ref, () => ({
    submit: () => {
      return handleSubmit();
    }
  }));

  useEffect(() => {
    fetchInfo();
  }, [loading?.editData]);

  useEffect(() => {
    const isAnyRequiredFieldEmpty = !editedState?.nameAsPerDocument;
    loading.setSubmitButtonDisabledForParent(isAnyRequiredFieldEmpty);
  }, [editedState]);

  console.log('nameAsPerDocument', loading, name, info);

  // return <></>;
  return (
    <Grid
      container sx={{
        padding: '0em 0em',
      }}>
      <CustomTextField
        id={`nameAsPerDocument`}
        required
        fullWidth
        disabled
        // autoFocus={j === 0}
        type="text"
        sx={{ margin: '0em 0', color: '#000000' }}
        label="Your Name as per certificate"
        placeholder={''}
        value={editedState?.nameAsPerDocument}
        inputProps={{ style: { color: '#000000' } }}
        onChange={(e) => {
        }}
        InputLabelProps={{ shrink: true }}
        helperText={<>
          This name has been sourced from your profile. If you require any changes, kindly&nbsp;
          <span
            style={{
              'fontWeight': 'bold',
              'cursor': 'pointer',
              'textDecoration': 'underline',
              '&:hover': {
                color: '#094754',
                textDecoration: 'underline'
              }
            }}
            onClick={async () => {
              mixpanel.track(trackingEvents.verification.events.enquireToEditNameOnTheCertificate);
              if (tawkToConfig(process.env.REACT_APP_ORG)) {
                window?.tawkToReactElement?.maximize();
                window?.$_Tawk?.maximize();
              } else {
                window.open(`mailto:${process.env.REACT_APP_ORG_MAIL}`, '_blank');
              }
            }}>
reach out
          </span>
          {' '}
to our support team for assistance
        </>}
        error={errors.nameAsPerDocument?.length > 0}
        onWheel={(e) => {
          e?.target?.blur();
        }}
      />
    </Grid>

  );
});
NameAsPerDocument.displayName = 'NameAsPerDocument';

export default NameAsPerDocument;
