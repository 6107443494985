const trackingEvents = {
  auth: {
    prefix: 'Auth - ',
    events: {
      sign: {
        in: {
          started: 'Auth - Sign In [Started]',
          requestedOTP: 'Auth - Sign In [OTP Requested]',
          verifiedOTP: 'Auth - Sign In [OTP Verified]',
          completed: 'Auth - Sign In [Completed]',
          failed: 'Auth - Sign In [Failed]',
          resendOTPDone: 'Auth - Sign In [OTP Resend - Done]',
          resendOTPFailed: 'Auth - Sign In [OTP Resent - Failed]',
        },
        out: 'Auth - Sign Out',
        up: {
          started: 'Auth - Sign Up [Started]',
          Responsed: 'Auth - Sign Up [Completed]',
          requestedOTP: 'Auth - Sign In [OTP Requested]',
          verifiedOTP: 'Auth - Sign In [OTP Verified]',
          completed: 'Auth - Sign In [Completed]',
          resendOTPDone: 'Auth - Sign Up [OTP Resend - Done]',
          resendOTPFailed: 'Auth - Sign Up [OTP Resent - Failed]',
        },

      },
    }
  },
  form: {
    prefix: 'Form - ',
    events: {
      select: {
        opened: 'Form - Opened',
        verificationStarted: 'Form - Verification Started',
        verificationSubmitted: 'Form - Verification Submitted',
        completed: 'Form - Completed'
      },
      addPurpose: {
        started: 'Form - Add Purpose [Started]',
        done: 'Form - Add Purpose [Done]',
        failed: 'Form - Add Purpose [Failed]'
      },

    }
  },
  verification: {
    prefix: 'Verification - ',
    events: {
      createProfile: {
        started: 'Create Profile [Started]',
        done: 'Create Profile [Done]',
        failed: 'Create Profile [Failed]'
      },
      skip: {
        started: 'Veriication - Skip [Started]',
        done: 'Veriication - Skip [Done]',
        failed: 'Veriication - Skip [Failed]'
      },
      submit: {
        started: 'Verification Data Submit [Started]',
        done: 'Verification Data Submit [Done]',
        failed: 'Verication Data Submit [Failed]'
      },
      edit: {
        started: 'Verification Edit [Started]',
        done: 'Verification Edit [Done]',
        failed: 'Verication Edit [Failed]'
      },
      delete: {
        started: 'Verification Delete [Started]',
        done: 'Verification Delete [Done]',
        failed: 'Verication Delete [Failed]'
      },
      customFormsValidationFailed: 'Verification - Custom Form Validation Failed - DEV',
      triggerSubmitVerification: 'Verification - Trigger Submit Verification - DEV',
      fileManagement: 'Verification - File Management - DEV',
      validation: 'Verification - Validation - DEV',
      addToProfile: 'Verification - Add To Profile - DEV',
      afterTriggerSubmitVerification: 'Verification - After Trigger Submit Verification - DEV',
      editing: 'Verification - Editing - DEV',
      deleting: 'Verification - Deleting - DEV',
      allDone: 'Verification Data Submit [Done] - DEV',
      movingToNextPage: 'Verification - Moving To Next Page - DEV',
      uploadFileWithOldVerification: 'Verification - Upload File With Old Verification - DEV',
      uploadingFile: 'Utility - Uploading File - DEV',
      enquireToEditNameOnTheCertificate: 'Verification - Enquire To Edit Name On The Certificate - DEV',
    }

  },
  payment: {
    prefix: 'Payment - ',
    events: {
      transaction: {
        initiationStarted: 'Payment Initiation Started',
        initiationFailed: 'Payment Initiation Failed',
        started: 'Payment Started',
        pending: 'Payment Pending',
        failed: 'Payment Failed',
        redirectionComplete: 'Payment Redirection Completed - Checking Payment Status',
        completed: 'Payment Completed',
        statusAPINotResponded: 'Payment Check Status API not responded'
      },
      step: {
        viewed: 'Payment Page Viewed'
      }
    }
  },
  tawkTo: {
    prefix: 'TawkTo - ',
    events: {
      widgetLoad: 'TawkTo - Widget load',
      widgetStarted: 'TawkTo - Widget started',
      chatStart: 'TawkTo - Chat start',
      chatEnd: 'TawkTo - Chat end',
      chatMessage: 'TawkTo - Chat message',
      chatReplied: 'TawkTo - Chat replied by someone',
      chatJoinedBySupport: 'TawkTo - Chat joined by support',
      chatJoinedByAI: 'TawkTo - Chat joined by AI',
    }
  }
};

export { trackingEvents };

