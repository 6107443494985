import { Avatar, Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import digilocker from '../../Assets/digilocker.png';
import Wave from '../../Components/Background/Wave';
import BottomComponent from '../../Components/UI/bottomComponent';
import { getDigilockerToken, getDlInDigilocker, getIssuedDocsFromDigilocker } from '../../Config/api';
import { UIStore, updateStore } from '../../Config/store';
import { appendLoggedIn, removeQueryFromURL, useQuery } from '../../Helper/helpers';
import { typesOfForm } from '../../Utils/enums';

import CustomTextField from '../../Components/UI/Input/customTextField';
import { getAxiosHeader } from '../../Config/api';
import { axiosInstanceWithoutBaseUrl } from '../../Config/axios';
import { apiEndpoints } from '../../Config/routes';
import { listOfSideProcesses } from '../DocRequest/sideProcesses';

export const openDigilocker = () => window.location.replace(`${process.env.REACT_APP_DIGILOCKER_URL}/api/1.0/digilocker/authorize?redirect=${window.location.origin}/digilocker`);

export default function Digilocker({ selectedModeOfVerification }) {
  const navigate = useNavigate();
  const query = useQuery();
  const submitRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDigilockerCredentials, setSelectedDigilockerCredentials] = useState({});
  const [dcs, setDcs] = useState({});
  const [field, setField] = useState({});
  const [loadingDialog, setLoadingDialog] = useState(false);
  const currentWorkingStepperStep = UIStore.useState((s) => s.currentWorkingStepperStep);
  const form = UIStore.useState((s) => s.form);
  const currentCredentialUnderFetch = form?.list?.[currentWorkingStepperStep];
  const searchedQuery = UIStore.useState((s) => s.searchedQuery);
  const credsWithDigilocker = UIStore.useState((s) => s.credsWithDigilocker);
  const digilockerToken = UIStore.useState((s) => s.digilockerToken);

  const fn = async () => {
    // alert(query.get('code'))
    if (query.get('code')) {
      setLoading(true);

      const token = await getDigilockerToken(query.get('code'));
      window.history.pushState({}, document.title, '');
      // const token = '4bd3f903126c2dcd7eaaeb36dd7c8e6c7a2aed26';
      if (token) {
        updateStore('digilockerToken', token);
        removeQueryFromURL();
        const docs = await getIssuedDocsFromDigilocker(token) ?? [];
        // const docs = [
        //   {
        //     'name': 'Aadhaar Card',
        //     'type': 'file',
        //     'size': '',
        //     'date': '24-11-2023',
        //     'parent': '',
        //     'mime': [
        //       'application/pdf'
        //     ],
        //     'uri': 'in.gov.uidai-ADHAR-720ae8a66696f94976759e2ad13764bc',
        //     'doctype': 'ADHAR',
        //     'description': 'Aadhaar Card',
        //     'issuerid': 'in.gov.uidai',
        //     'issuer': 'Unique Identification Authority of India (UIDAI)'
        //   },
        //   {
        //     'name': 'PAN Verification Record',
        //     'type': 'file',
        //     'size': '',
        //     'date': '09-06-2021',
        //     'parent': '',
        //     'mime': [
        //       'application/json',
        //       'application/xml',
        //       'application/pdf'
        //     ],
        //     'uri': 'in.gov.pan-PANCR-DVMPP2257J',
        //     'doctype': 'PANCR',
        //     'description': 'PAN Verification Record',
        //     'issuerid': 'in.gov.pan',
        //     'issuer': 'Income Tax Department'
        //   }
        // ];
        if (docs) {
          const obj = {};
          docs.forEach((zz) => {
            const temp = Object.keys(credsWithDigilocker)
                .find((x) => credsWithDigilocker[x].doctype === zz.doctype);
            if (temp) {
              obj[temp] = { uri: zz.uri, key: temp };
            }
          });
          console.log('temp', obj, credsWithDigilocker);
          if (token) {
            if (Object.keys(obj)?.length === 0) {
              window.snackbar('error', 'Some Requested credential not found');
              const index = Object.keys(credsWithDigilocker);
              // setOpen({
              //   info: credsWithDigilocker[index[0]],
              //   name: index[0]
              // });
            } else if (Object.keys(obj)?.length > 0) {
              setDcs(obj);
              // navigate('/displayInfo', { state: { showLoading: false, uris: obj } });
            }
            setLoading(false);
          } else {
            navigate('/docs');
          }
        } else {
          navigate(searchedQuery?.link);
        }
      }
    } else {
      openDigilocker();
    }
  };
  useEffect(() => {
    fn();
  }, [searchedQuery, currentCredentialUnderFetch]);

  console.log('field,selectedModeOfVerification, selectedDigilockerCredentials:', field, selectedModeOfVerification, selectedDigilockerCredentials);
  return (
    <>
      <Wave image={digilocker} />
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{ padding: '1em', height: '100%' }}>

        {/* {Object.values(dcs).filter((x) => x)?.length > 0 ? <Typography
          variant="body1" sx={{
            textAlign: 'left',
            marginLeft: '1em'
          }}>
          {' '}
          We found these documents in your Digilocker Wallet, upload a document to complete checkin
          {' '}
        </Typography> :
          <CircularProgress sx={{ margin: 'auto' }} />} */}
        {
          loading ? <CircularProgress sx={{ margin: 'auto' }} /> : <>
            <List dense sx={{ width: '100%' }}>
              {typeof credsWithDigilocker === 'object' ?
              Object.keys(credsWithDigilocker)?.map((item, i) => {
                return (
                  <ListItem
                    key={`dl-${i}`}
                    sx={{ border: '1px solid #DADADA', borderRadius: '8px', margin: '1em 0', padding: '1em' }}
                    secondaryAction={dcs[item] ? <Checkbox
                      checked={(selectedDigilockerCredentials[item])}
                      onChange={(e) => {
                        setSelectedDigilockerCredentials((j) => {
                          const temp = { ...j };
                          if (e.target.checked) {
                            temp[item] = dcs[item];
                          } else {
                            delete temp[item];
                          }
                          return temp;
                        });
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    /> : <Button
                      variant="text"
                      sx={{ marginRight: 0, paddingRight: 0 }}
                      onClick={() => {
                        if (credsWithDigilocker[item]?.pull) {
                          setOpen({
                            info: credsWithDigilocker[item],
                            name: item
                          });
                        } else {
                          // const index = Object.keys(form?.list)?.findIndex((x) => x === item);
                          updateStore('currentWorkingStepperStep', item);
                          navigate('/docs', { state: { openThis: true } });
                        }
                      }}>
                      {credsWithDigilocker[item]?.pull ? 'Pull' : 'Fetch'}
                    </Button>}
                  >
                    <ListItemAvatar sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                      {/* {
                form?.list?.[c]?.done ?
                  <CheckCircle
                    color="success" sx={{
                      fontSize: '1rem'
                    }} /> : <AccessTime
                      color="default" sx={{
                        fontSize: '1rem'
                      }} />
              } */}
                      <Avatar sx={{
                        border: '1px solid #E7EDF6',
                        backgroundColor: '#ffffff',
                        margin: 'auto 1em 0 0',
                        height: '60px',
                        width: '60px', padding: '4px'
                      }}>
                        <img src={form?.list?.[item]?.icon} style={{ height: '32px' }} alt="logo" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={form?.list?.[item]?.name}
                      secondary={(<span>
                        {form?.list?.[item]?.orgName}
                      </span>)}
                      primaryTypographyProps={{
                        sx: {
                          fontWeight: 'bold',
                          color: '#000000',
                          margin: '0em',
                          // color: c?.dependency?.credential ?
                          //   (form?.list?.[c.dependency.credential]?.done ?
                          //     (form?.list?.[c]?.done ? '#000000' : '#000000') :
                          //     '#aaaaaa') :
                          //   (form?.list?.[c]?.done ? '#000000' : '#000000')
                        }
                      }}
                      secondaryTypographyProps={{ sx: { margin: '0em', padding: '0em' } }}
                    />
                  </ListItem>
                );
              }) : null}
            </List>
            <BottomComponent>

              <Button
                variant="contained" color="primary" sx={{ width: '100%' }}
                disabled={Object.keys(selectedDigilockerCredentials)?.length <= 0}
                onClick={async () => {
                  // updateStore('currentCredentialUnderFetch', form?.list?.[c]);
                  // navigate('/docs');

                  if (!form?.formDisabled) {
                    navigate('/displayInfo', {
                      state: {
                        showLoading: false, uris: selectedDigilockerCredentials,
                        digilockerToken
                      }
                    });
                  } else {
                    const configBody = form?.list?.[currentCredentialUnderFetch.key]?.data?.authOptions?.
                        find((x) => x?.type === typesOfForm.DIGILOCKER);
                    const bodyMaker = eval(configBody?.api?.body);
                    console.log('bodyMaker2', bodyMaker);
                    const body = bodyMaker({
                      'token': digilockerToken,
                      'verificationName': currentCredentialUnderFetch.key
                    });

                    await axiosInstanceWithoutBaseUrl(`${configBody?.api?.endpointBaseUrl ?
                      apiEndpoints[configBody?.api?.endpointBaseUrl](currentCredentialUnderFetch.key) :
                      configBody?.api?.endpoint}`, {
                      method: configBody?.api?.method?.toUpperCase(),
                      data: {
                        data: { ...body },
                        ...body
                      },
                      ...getAxiosHeader({})
                    }).then((res) => {
                      console.log('response', res);
                      if (res && res?.data?.data) {
                        if (listOfSideProcesses?.[currentCredentialUnderFetch?.key]) {
                          listOfSideProcesses?.[currentCredentialUnderFetch?.key](tempFormResponse);
                        }

                        setTimeout(() => {
                          if (form?.locationState?.link) {
                            navigate(appendLoggedIn(form?.locationState.link), { state: { ...form?.locationState?.nextState } });
                          } else {
                            navigate('/' + '?loggedIn=true');
                          }
                        }, 1000);
                      } else {
                        // navigate('/setup?loggedIn=true', { state: form?.locationState, disableBack: true });
                      }
                    });
                  }
                }}>
                Continue
              </Button>
            </BottomComponent>
          </>
        }

        <Dialog
          maxWidth="xs"
          fullWidth
          open={open}
          onClose={() => setOpen(false)}
          sx={{ padding: '1em' }}
        >
          <DialogTitle>
            Pull
            {' '}
            {form?.list?.[open.name]?.name}
            {' '}
            into Digilocker
          </DialogTitle>
          {!loadingDialog ? <DialogContent>
            {
              open?.info?.pull?.inputs?.map((input, i) => <CustomTextField
                key={`${input.label}-${i}`}
                fullWidth autoFocus={i === 0}
                sx={{ margin: '1em 0' }}
                id="Name"
                label={`${input.label}`}
                value={field[input.fieldName]}
                onChange={(event) => {
                  event.target.value = event.target.value.trimStart();
                  setField((x) => {
                    const temp = { ...x };
                    temp[input.fieldName] = event.target.value;
                    return temp;
                  });
                }}
              // onKeyDown={(e) => {
              //   if (e.key === 'Enter') {
              //     submitRef.current.click();
              //   }
              // }}
              // InputProps={{
              //   startAdornment: <InputAdornment position="start">+91-</InputAdornment>,
              // }}
              />)
            }
            <Button
              ref={submitRef} variant="contained" color="primary"
              onClick={async () => {
                setLoadingDialog('Fetching...');
                await getDlInDigilocker(field, open?.info?.pull?.qsBody)
                    .then(async (result) => {
                      console.log('result', result);
                      if (result?.uri) {
                      // await masterDigilocker(result?.uri);
                        const temp = {
                          [open.name]: { uri: result?.uri, key: open.name }
                        };
                        setSelectedDigilockerCredentials({
                          ...selectedDigilockerCredentials,
                          ...temp
                        });
                        setDcs({ ...dcs, ...temp });
                      }
                    }).catch((e) => {
                      console.log('error in digilocker', e);
                    }).finally(() => {
                      setLoadingDialog(false);
                      setOpen(false);
                    });
              }}>
              Fetch
            </Button>
          </DialogContent> : <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center">
            <CircularProgress sx={{ margin: '1em auto' }} />
            <Typography variant="body2">
              {loadingDialog}
            </Typography>
          </Grid>}
        </Dialog>
      </Grid>
    </>

  );
}
