import { CheckCircleOutline, ChevronRight, SwitchAccessShortcut, WarningOutlined } from '@mui/icons-material';
import { Avatar, Chip, CircularProgress, Divider, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import greenCheckThorned from '../../../Assets/greenCheckThorned.svg';
import SimpleBackground from '../../../Components/Background/Simple';
import { getConfigs, getTxOrgId, getVerificationFromProfileSync } from '../../../Config/api';
import { UIStore, updateStore } from '../../../Config/store';
import theme from '../../../Config/theme';
import { getFetchProcessInterval, triggerCancellationController, useQuery } from '../../../Helper/helpers';
import { ignoreKeysForProfile, statesEnum } from '../../../Utils/enums';
import { configsForMandatoryFormsToFill } from '../../MandatoryFormsToFill/config';
import { mixpanel, trackingEvents } from '../../../Monitoring/Mixpanel';

const ignoreKeys = ignoreKeysForProfile;
const colors = (x, status) => {
  if (x?.error) {
    return {
      bgcolor: theme?.palette?.error?.light,
      border: `1px solid ${theme?.palette?.error?.main}`
    };
  } else if (status?.statesForMandatoryStuffs?.done) {
    return {
      bgcolor: theme.palette.success.light,
      border: '1px solid #E7EDF6'
    };
  } else if (status?.statesForMandatoryStuffs?.existInWallet) {
    return {
      bgcolor: theme.palette.secondary.light,
      border: '1px solid #E7EDF6'
    };
  } else if (x?.markedAsSkip) {
    return {
      bgcolor: theme?.palette?.secondary?.veryLight,
      border: '1px solid #E7EDF6'
    };
  } else {
    return {
      bgcolor: '#ffffff',
      //   border: `2px solid ${theme.palette.primary.main}`
    };
  }
};


export default function ProfileVerifications({ enabledDocs, setEnabledDocs }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams();
  const query = useQuery();
  const { state } = useLocation();
  const lottieRef = useRef(null);
  const [identityKeysRequired, setIdentityKeysRequired] = React.useState([]);
  const [status, setStatus] = React.useState(false);
  const [identityKey, setIdentityKey] = useState('');
  const [selecetedCred, setSelecetedCred] = useState('');
  const [errorDialog, setErrorDialog] = useState(false);
  const form = UIStore.useState((s) => s.form) ?? {};
  const currentUserData = UIStore.useState((s) => s.currentUserData);
  const formUnderProcess = UIStore.useState((s) => s.formUnderProcess);
  const infoSubmitted = UIStore.useState((s) => s.infoSubmitted);
  const userInput = UIStore.useState((s) => s.userInput);
  const formInfo = UIStore.useState((s) => s.formInfo);
  const [txInfo, setTxInfo] = React.useState({});

  const identityKeyInfo = form?.list?.[identityKey] ? form?.list?.[identityKey] : configsForMandatoryFormsToFill?.list?.[identityKey];

  const getIdentityKeyInfo = async (identityKeys = identityKeysRequired) => {
    const identity2 = [];

    setIdentityKey(identityKeys);
    if (!identityKeys) {
      return null;
    }

    let configs = await getConfigs(identityKeys);
    if (configs) {
      configs = configs.reduce((acc, item) => {
        acc[item.name] = item;
        return acc;
      }, {});

      const formConfig = {};
      const formInfoConfig = {};
      for await (const idKey of identityKeys) {
        const state = getVerificationFromProfileSync(configs?.[idKey], { data: currentUserData });
        formConfig[idKey] = {
          ...(form?.list?.[idKey] ? form?.list?.[idKey] : {}),
          ...configs?.[idKey],
          // 'existInWallet': state?.state !== statesEnum.NOT_INITIALIZED,
          // 'done': state?.state === statesEnum.COMPLETED,
          // 'existInWallet': true,
          // 'done': true,
          'key': idKey,
          'name': configs?.[idKey]?.additionalInfo?.name,
          'data': configs?.[idKey]?.additionalInfo,
          'icon': configs?.[idKey]?.metadata.iconUrl,
          'description': configs?.[idKey]?.metadata.description,
          'orgName': configs?.[idKey]?.metadata.issuerName,
          'logo': configs?.[idKey]?.metadata.issuerLogo,
          'category': { ...configs?.[idKey]?.category },
          'markedAsSkip': false,
          'error': state?.state === statesEnum.FAILED,
          'settingProfile': true,
          'consentMessage': configs?.[idKey]?.metadata?.consent?.[process.env.REACT_APP_ORG] || configs?.[idKey]?.metadata?.consent?.default

        };
        formInfoConfig[idKey] = {
          'skipAllowed': false,
          'consentMessage': null,
          'state': state?.state,
          'markedAsSkip': false,
          'marksheetRequired': false
        };
      }

      updateStore('form', {
        ...form, list: {
          ...form?.list,
          ...formConfig

        }
      });

      updateStore('formInfo', {
        ...formInfo,
        ...formInfoConfig

      });
      setStatus({
        'statesForMandatoryStuffs': {
          existInWallet: currentUserData?.profileDetails?.name,
          done: currentUserData?.profileDetails?.name
        }
      });
      return {
        // existInWallet: getVerificationFromProfileSync(configs?.[identity], { data: currentUserData })?.state !== statesEnum.NOT_INITIALIZED,
        // done: getVerificationFromProfileSync(configs?.[identity], { data: currentUserData })?.state === statesEnum.COMPLETED,

        existInWallet: currentUserData?.profileDetails?.name,
        done: currentUserData?.profileDetails?.name
      };
      // }
    };
  };

  useEffect(() => {
    if (!form?.manualProfileRequired) {
      const verificationsForProfile = (process.env.REACT_APP_VERIFICATIONS_FOR_PROFILE).split(',');
      getIdentityKeyInfo(verificationsForProfile);
      setIdentityKeysRequired(verificationsForProfile);
    }
  }, [form?.manualProfileRequired, currentUserData]);

  useEffect(() => {
    const verificationsForProfile = (process.env.REACT_APP_VERIFICATIONS_FOR_PROFILE).split(',');
    setIdentityKeysRequired(verificationsForProfile);
  }, []);

  return (
    <SimpleBackground
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'nowrap',
        height: '100%',
        padding: '0 1em',
        margin: 'auto'
      }}
      title={'Please select a document to start with'}>
      {' '}
      {!form?.manualProfileRequired ? identityKeysRequired?.map((id, i) => <React.Fragment key={`${id}-${i}`}>
        <ListItem
          component={ListItemButton}
          sx={{
            'flexGrow': 0,
            //   'border': '1px solid #E7EDF6',
            'borderRadius': '8px',
            'padding': '2em 0em',
            'margin': '1em 0em',
            'cursor': 'pointer',
            'boxShadow': '0px 4px 6px 0px #0000001A',
            ...colors(identityKeyInfo, status)
          }}
          secondaryAction={!identityKeyInfo?.error ?
            (!identityKeyInfo?.markedAsSkip ? (
              status?.statesForMandatoryStuffs?.done ?
                <Chip
                  color="success" variant="outlined"
                  sx={{
                    'border': 'none',
                    '& .MuiChip-label': { fontSize: '12px', fontWeight: 'bold' }
                  }}
                  size="small"
                  icon={<img
                    src={greenCheckThorned}
                    style={{ width: '20px' }} />}
                  label="Verified" /> :
                (status?.statesForMandatoryStuffs?.existInWallet ?
                  <Chip
                    color="primary"
                    variant="outlined"
                    sx={{
                      'border': 'none',
                      '& .MuiChip-label': { fontSize: '12px', fontWeight: 'bold' }
                    }}
                    size="small"
                    icon={<CheckCircleOutline
                      style={{ width: '20px' }} />}
                    label="Submitted" /> :
                  (selecetedCred === identityKey ?
                    <CircularProgress /> :
                    <ChevronRight
                      color="grey" />))
            ) : <Chip
              color="black"
              variant="outlined"
              sx={{
                'border': 'none',
                '& .MuiChip-label': { fontSize: '12px', fontWeight: 'bold' }
              }}
              size="small"
              icon={<SwitchAccessShortcut
                style={{ width: '20px', transform: 'rotate(90deg) ' }} />}
              label="Skipped" />) :
            (<div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <Chip
                color="error" variant="outlined"
                sx={{
                  'border': 'none',
                  '& .MuiChip-label': { fontSize: '12px', fontWeight: 'bold' }
                }}
                size="small"
                icon={<WarningOutlined />}
                label="Action Required" />
              <ChevronRight
                color="grey" />
            </div>)}
          onClick={async () => {
            // if (infoSubmitted[identityKey]) {
            //   updateStore('docData', infoSubmitted[identityKey]);
            //   return navigate('/displayInfo', { state: { onlyShow: true } });
            // } else
            mixpanel.track(trackingEvents.verification.events.createProfile.started, {
              name: id,
              status: 'SUCCESS',
              ...getTxOrgId()
            });
            if (!currentUserData?.profileDetails?.name) {
              updateStore('isAutoplay', false);
              clearInterval(getFetchProcessInterval());
              triggerCancellationController();

              updateStore('currentWorkingStepperStep', id);
              setTimeout(() => {
                navigate('/docs', { state: { openThisFlow: true } });
              }, 500);
            } else {
              const subData = Object.keys(currentUserData.profileDetails)
                  ?.reduce((acc, x) => {
                    if (!ignoreKeys.includes(x)) {
                      acc[x] = currentUserData.profileDetails[x];
                    }
                    return acc;
                  }, { mobile: currentUserData?.mobile });
              if (subData) {
                updateStore('isAutoplay', false);
                clearInterval(getFetchProcessInterval());
                triggerCancellationController();
                updateStore('currentWorkingStepperStep', id);
                const tempz = subData;

                updateStore('docData', tempz);
                if (!identityKeyInfo?.error) {
                  if (subData) {
                    return navigate('/displayInfo', { state: { onlyShow: true } });
                  }
                } else {
                  window.snackbar('info', 'Details are being submitted, kindly wait a few minutes');
                }
              } else {
                window.snackbar('info', 'Details are being submitted, kindly wait a few minutes');
              }
            }

            setSelecetedCred(null);
          }}>
          <ListItemAvatar sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 0,
            padding: 0,
            minWidth: '40px'
          }}>
            <Avatar style={{
              // border: '1px solid #E7EDF6',
              backgroundColor: 'transparent',
              height: '30px',
              width: '30px', margin: '0 1em'
            }}>
              <img
                src={form?.list?.[id]?.icon}
                style={{
                  minHeight: '15px',
                  maxHeight: '25px', margin: '0', objectFit: 'contain'
                }} alt="logo" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            sx={{ padding: '0 0.4rem', maxWidth: '66%' }}
            primary={form?.list?.[id]?.name}
            primaryTypographyProps={{
              variant: 'body2',
              noWrap: true,
              sx: {
                margin: '0em',
                // color: c?.dependency?.credential ?
                //   (configsForMandatoryFormsToFill?.list?.[c.dependency.credential]?.done ?
                //     (configsForMandatoryFormsToFill?.list?.[c]?.done ? '#000000' : '#000000') :
                //     '#aaaaaa') :
                //   (configsForMandatoryFormsToFill?.list?.[c]?.done ? '#000000' : '#000000')
              }
            }}
            secondaryTypographyProps={{
              variant: 'body2',
              noWrap: true,
              sx: { margin: '0em', padding: '0em', maxWidth: '80%' }
            }}
          />
        </ListItem>
        {(i + 1) < identityKeysRequired?.length &&
          <Divider fullWidth sx={{ width: '100%' }}>
            <Typography
              variant="body1" sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              OR
            </Typography>
          </Divider>}
      </React.Fragment>) : null}
    </SimpleBackground>


  );
}
