export default {
  topGrid: {
    display: 'flex',
    height: '100%',
    flexFlow: 'column',
    rowGap: '2rem',
    justifyContent: 'center',
    alignItems: {
      xs: 'center',
      blg: 'flex-start'
    },
    pl: {
      xs: '2.5vw',
      sm: '18vw',
      md: '20vw',
      blg: '3.4vw',
      xl: '12vw'
    },
    pr: {
      xs: '2.5vw',
      sm: '18vw',
      md: '20vw',
      blg: '0'
    },
    mb: {
      sm: '1.5rem',
      blg: 0
    }
  },
  loginWrapper: {
    // position: 'absolute',
    // top: '50%', left: '50%',
    // transform: {xs: 'translate(-50%,-50%)', sm: 'translate(-50%,-54%)', blg: 'translate(-43%,-60%)'},
    backgroundColor: '#fff',
    borderRadius: '8px',
    width: {xs: '90%', md: '80%', lg: '50%'},
    height: 'fit-content',
    margin: 'auto',
    // minHeight: {xs: '82%', sm: '55%', blg: '52%'},
    boxShadow: '0px 6px 12px -1px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(237, 237, 237, 1)',
    display: 'flex',
    flexFlow: 'column',
    maxWidth: '600px',
  },
  loginWrapperMobile: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0px 6px 12px -1px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(237, 237, 237, 1)',
    display: 'flex',
    flexFlow: 'column',
    flex: 1,
    minHeight: 0,
    p: '.55rem',
  },
  topbar: {
    backgroundColor: '#fff',
    // borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'space-between',
    p: {
      xs: '.9rem 3.25vw',
      sm: '1.5rem 4vw',
    },
    // pl: {
    //   xs: '3.25vw',
    //   sm: '18vw',
    //   md: '20vw',
    //   blg: '3.4vw',
    //   xl: '12vw'
    // },
    mb: {
      // sm: '3.5rem',
      blg: 0
    }
  }
};
