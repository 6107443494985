import { Grid, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

export default function DataCard({ data, type, sx = {} }) {
  const [dataConfirm, setDataConfirm] = useState(null);

  console.log('dataConfirm', data, dataConfirm, type);
  useEffect(() => {
    setDataConfirm(getFormattedData(data, type));
  }, [data]);

  return (<Grid
    container
    direction="row"
    sx={{
      margin: '1em 1em',
      padding: '1em',
      border: '1px solid #DADADA',
      borderRadius: '8px',
      width: '96%',
      ...sx
    }}>
    <Grid item xs={1}>
      {/* {typeof dataConfirm?.title === 'string' ? <BackgroundLetterAvatars name={dataConfirm?.title ?? ''} /> : null} */}
    </Grid>
    <Grid item xs={11}>
      <Typography variant="h6" color={'primary'} sx={{ padding: '0em', margin: '2px' }}>
        {dataConfirm?.title ? dataConfirm?.title : <Skeleton
          variant="rectangular" sx={{
            borderRadius: '6px',
            width: '12ch',
            height: '18px',
          }} />}

      </Typography>
      <Typography variant="body2" color={'grey'} sx={{ padding: '0em', margin: '2px' }}>
        {dataConfirm?.subtitle !== null ? dataConfirm?.subtitle : <Skeleton
          variant="rectangular" sx={{
            borderRadius: '6px',
            width: '50%',
            height: '18px',
          }} />}
      </Typography>
      {dataConfirm?.subtitle2 ? <Typography variant="body1" sx={{ padding: '0em', fontWeight: 600, margin: '2px' }}>
        {dataConfirm?.subtitle2}
      </Typography> : null}
      {dataConfirm?.ar?.length > 0 ? dataConfirm.ar?.map((q, i) => <Typography
        variant="body2"
        sx={{ padding: '0em', fontWeight: 600, margin: '2px' }}
        key={q}>
        {q}
      </Typography>) : null}
    </Grid>
  </Grid>

  );
}

export const getFormattedData = (data, type) => {
  console.log('data:', data);

  if (typeof data === 'string') {
    return ({
      title: data
    });
  }
  switch (type) {
    case 'bgv-degree': return ({
      title: data?.level,
      subtitle: data.issueDate,
      subtitle2: data.nameAsPerDocument,
      ar: [data.nameOfInstitute, data.registrationNumber]
    });
    case 'bgv-employment': {
      return ({
        title: data.establishmentName,
        subtitle: `${data?.dateOfJoining} to ${data?.dateOfExit ?? 'Present'}`,
        subtitle2: `${data?.name}    •    ${data?.guardianName}`,
        ar: [data.memberId]
      });
    }
    case 'din': {
      return ({
        title: data.company_name || data?.companyName,
        subtitle: (data?.begin_date?.length || data?.beginDate?.length) > 2 ? `${data?.begin_date || data?.beginDate} to ${(data?.end_date?.length || data?.endDate?.length) > 1 ?
          (data?.end_date || data?.enddate) : 'Present'}` : '',
        subtitle2: `${data?.active_compliance || data?.activeCompliance}`,
        ar: [`CIN/FCRN: ${data?.['cin/fcrn'] || data?.['cinOrFcrn']}`]
      });
    }
    case 'bgv-criminal': return ({
      title: data.pincode,
      subtitle: data?.state ? `${data?.district}, ${data?.state}` : null,
      subtitle2: data?.line1,
      ar: []
    });

    case 'address': return ({
      title: data.pincode,
      subtitle: data?.state ? `${data?.district}, ${data?.state}` : null,
      subtitle2: data?.line1,
      ar: []
    });
    default: return null;
  }
};
