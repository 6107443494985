/* eslint-disable */
import { ContentCopy, InfoOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Avatar, Box, Chip, Divider, Grid, List, ListItem, ListItemText, Stack, Tooltip, Typography } from '@mui/material';
import { default as React, useRef, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import SimpleBackground from '../../Components/Background/Simple';
import BottomComponent from '../../Components/UI/bottomComponent';
import { getPaidTxDetails, getPaymentStatus, getTxInfo, initPayment } from '../../Config/payment/api';
import SDK_LIST from '../../Config/sdk';
import { UIStore, patchStore } from '../../Config/store';
import { displayTime } from '../../Helper/dates';
import { useQuery } from '../../Helper/helpers';
import { mixpanel, trackingEvents } from '../../Monitoring/Mixpanel';
import { states } from '../../Utils/enums';

const style = {
  py: 0,
  width: '100%',
  // maxWidth: 360,
  borderRadius: 2,
  border: '1px solid',
  borderColor: 'divider',
  backgroundColor: 'background.paper',
  marginTop: 2
};

const Payment = () => {
  const navigate = useNavigate();
  const { state } = useLocation()

  const [txInfo, setTxInfo] = useState([])
  const [paymentStatus, setPaymentStatus] = useState('')

  const searchParams = useQuery();
  const [loadingData, setLoadingData] = useState(true)
  const [previousRegion, setPreviousRegion] = useState(null)
  const [showCashfreeSdk, setShowCashfreeSdk] = useState(false)
  const [cashfreePaymentSessionId, setCashfreePaymentSessionId] = useState(null)
  const discountRef = useRef(null)

  const searchedQuery = UIStore.useState((s) => s.searchedQuery);
  const orgId = searchParams.get("orgId") || searchedQuery?.orgId
  const txId = searchParams.get("txId") || searchedQuery?.txnId
  const region = searchParams.get("region") || previousRegion
  const provider = searchParams.get("provider")
  const orderId = searchParams.get("order_id")

  const [loading, setLoading] = useState(false)
  const [billingDetails, setBillingDetails] = useState({})
  const paymentPollTimeout = React.useRef(20)
  const url = new URL(document.URL)
  const txHistoryView = url?.search?.includes("transactionHistory")
  const CURRENCY_SYMBOL = txInfo?.currencySymbol ?? ""

  const getTxInfoData = async () => {
    patchStore('searchedQuery', {
      orgId: orgId?.length ? orgId : searchParams.get("orgId"),
      txnId: txId?.length ? txId : searchParams.get("txId")
    })

    const param1 = orgId?.length ? orgId : searchParams.get("orgId")
    const param2 = txId?.length ? txId : searchParams.get("txId")

    let response
    if (orderId) {
      response = await getPaidTxDetails(param1, param2, orderId)
      if (response?.data?.region) {
        setPreviousRegion(response?.data?.region)
      }
    }
    else {
      response = await getTxInfo(param1, param2, region, orderId)
    }

    console.log("response", response)
    setLoadingData(false)
    if (response) {
      generateBillingDetails(response.data)
      setTxInfo(response.data)
      if (response?.data?.discount) {
        discountRef.current = response?.data?.discount
      }
      mixpanel.track(trackingEvents.payment.events.step.viewed, {
        orgId: orgId,
        txId: txId,
        region,
        discount: discountRef?.current
      })
    }
  }

  const pasteToClipboard = async (text) => {
    navigator.clipboard.writeText(text);
    window.snackbar('success', 'Copied')
  }

  React.useEffect(() => {

    if (!region && !orderId && !txHistoryView) {
      window.snackbar('error', 'Please choose a payment region before proceeding to pay')
      navigate('/choose-payment-region')
      return
    }

    if (orgId && txId && !txHistoryView) {
      getTxInfoData()
    }
    else if (txHistoryView) {
      const data = state?.order;
      if (data) {
        setTxInfo({
          ...data,
          items: data?.price?.items,
          total: data?.price?.toPay,
          toPay: data?.amount,
          gst: data?.price?.gst,
          orderId: data?._id,
          transactionId: data?.transactionId,
          org: data?.org,
          updatedAt: data?.updatedAt,
          currentStatus: data?.currentStatus,
          paymentId: data?.paymentId,
          currencySymbol: data?.currencySymbol ?? data?.price?.currencySymbol,
          discount: data?.discount,
          isRefundTx: data?.refundDetails?.length > 0,
          totalAmountPaid: data?.totalAmountPaid
        })
      }
      else {
        navigate('/transaction-history')
      }
    }
  }, [])

  const pollStatus = () => {
    const id = setInterval(async () => {
      paymentPollTimeout.current = paymentPollTimeout.current - 1
      if (paymentPollTimeout.current <= 0) {
        clearInterval(id)
        window.snackbar('error', 'Payment Failed. Please try again')
        setLoading(false)
        setPaymentStatus('')
        mixpanel.track(trackingEvents.payment.events.transaction.pending, {
          orgId: orgId,
          txId: txId,
          orderId,
          region,
          provider,
          discount: discountRef?.current
        })
      }
      const response = await getPaymentStatus(orgId, orderId)
      if (!response.error) {
        setPaymentStatus(response.data.data.currentStatus)
        if (response.data.data.currentStatus == "Success") {
          clearInterval(id)
          setLoading(false)
          setPaymentStatus('Success')
          mixpanel.track(trackingEvents.payment.events.transaction.completed, {
            orgId: orgId,
            txId: txId,
            orderId,
            region,
            provider,
            discount: discountRef?.current
          })
        }
        if (response.data.data.currentStatus == "Failed") {
          clearInterval(id)
          window.snackbar('error', 'Payment Failed. Please try again')
          setLoading(false)
          setPaymentStatus('Failed')
          mixpanel.track(trackingEvents.payment.events.transaction.failed, {
            orgId: orgId,
            txId: txId,
            orderId,
            region,
            provider,
            discount: discountRef?.current
          })
        }
      }
      else {
        clearInterval(id)
        setLoading(false)
        mixpanel.track(trackingEvents.payment.events.transaction.statusAPINotResponded, {
          orgId: orgId,
          txId: txId,
          orderId,
          errorMessage: response?.message,
          region,
          provider,
          discount: discountRef?.current
        })
      }

    }, 6000)
  }
  React.useEffect(() => {
    const trigger = async () => {
      const paymentQuery = "order_id="
      const url = new URL(document.URL)
      if (url?.search?.includes(paymentQuery)) {
        setLoading(true)
        pollStatus()
        mixpanel.track(trackingEvents.payment.events.transaction.redirectionComplete, {
          orgId: orgId,
          txId: txId,
          orderId,
          region,
          discount: discountRef?.current
        })
      }
    }
    // in case of paypal, when payment is cancelled and payment region redirects the user back to wallet
    if (searchParams?.get("cancelled")) {
      window.snackbar('error', 'Payment Cancelled')
    }
    else {
      trigger()
    }

  }, [])

  const generateRedirectionLink = (environment, sdkName, sessionId) => {
    return `${process.env.REACT_APP_PAYMENT_REDIRECTION_ORIGIN}?sdkProvider=${sdkName}&paymentSessionId=${sessionId}&environment=${environment}`
  }


  const initiatePayment = async () => {
    if (!region) {
      window.snackbar("error", "Please select the payment option first")

      return <Navigate to="choose-payment-region" />
    }
    mixpanel.track(trackingEvents.payment.events.transaction.initiationStarted, {
      orgId: orgId,
      txId: txId,
      region,
      discount: discountRef?.current
    })
    setLoading(true)
    const response = await initPayment(orgId, txId, region)
    if (response.data) {
      mixpanel.track(trackingEvents.payment.events.transaction.started, {
        orgId: orgId,
        txId: txId,
        region,
        redirectInfo: response?.data?.data?.redirectInfo,
        discount: discountRef?.current
      })
      if (response?.data?.data?.provider == SDK_LIST.CASHFREE && response?.data?.data?.paymentSessionId) {
        setShowCashfreeSdk(true)
        setCashfreePaymentSessionId(response?.data?.data?.paymentSessionId)
        window.location.href = generateRedirectionLink(process.env.REACT_APP_CASHFREE_PAYMENT_MODE, SDK_LIST.CASHFREE, response?.data?.data?.paymentSessionId)
        return
      }
      else {
        window.location.href = response?.data?.data?.redirectInfo?.web;
      }
    }
    else {
      setLoading(false)
      mixpanel.track(trackingEvents.payment.events.transaction.initiationFailed, {
        orgId: orgId,
        txId: txId,
        region,
        discount: discountRef?.current
      })
    }
  }

  const generateBillingDetails = (data) => {
    let total = 0
    data?.verificationList?.map((i) => {
      total += i.credits
    })
    const gst = 0.18 * total
    const toPay = total + gst
    setBillingDetails({
      total, gst, toPay
    })
  }

  const getGstPercentage = () => {
    if (!txInfo?.gst || !txInfo?.total) return null
    const total = txInfo?.total - (txInfo?.discount?.amount ?? 0)
    const percentage = (txInfo?.gst / (total)) * 100
    return `${Math.round(percentage)}%`
  }

  if (paymentStatus == 'Success') return <Navigate to="/submitted" />
  const gstPercentage = getGstPercentage()
  return <SimpleBackground
    sx={{
      // maxWidth: '400px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flex: 1,
      // alignItems: 'center',
      // height: '100%',
      padding: '0',
      margin: 'auto',
    }}
    title={'Payment Details'}>
    {url?.search?.includes('transactionHistory') ? <Grid
      sx={{ mt: 2, backgroundColor: "#E4F1FF", padding: "10px", borderRadius: 2 }}
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center">
      <Box>
        <Grid container
          direction="row"
          alignItems="center"
        >
          <Avatar sx={{ width: 40, height: 40 }} src={txInfo?.org?.imageUrl} />
          <Box>
            <Typography sx={{ ml: "20px" }}>
              {txInfo?.org?.displayName}
              <Typography sx={{ color: '#8B8B8B', mt: "-3px" }} >
                {displayTime(txInfo?.createdAt, 'DD-MMM-YYYY, hh:mm:A')}
              </Typography>
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Box>
        {txInfo?.currentStatus == "Success" && <img style={{ width: 100 }} src={require('../../Assets/completed.png')} />}
        {txInfo?.currentStatus == "Failed" && <Typography sx={{ color: '#C84646', fontWeight: 'bold' }} >Failed</Typography>}
        {txInfo?.currentStatus == "Pending" && <Typography sx={{ color: '#F8A908', fontWeight: 'bold' }} >Pending</Typography>}
      </Box>
    </Grid> : null}

    {/* <Grid container justify="space-between" sx={{ mt: 2, mb: 0, }}>
      <Typography sx={{ textAlign: 'left' }} variant="h1" component="h2">
        Submitted Verifications
      </Typography>
    </Grid>
    <List sx={style}>
      {txInfo?.items?.map((item) => {
        return <span>
          <ListItem
            disablePadding
            secondaryAction={
              <Typography sx={{ fontWeight: '500' }}>
                {`${CURRENCY_SYMBOL} ${item?.price}` ?? '-'}
              </Typography>
            }>
            <ListItemText sx={{ marginLeft: 1, fontWeight: '400' }} primary={`${item?.description} ${item?.label}`} />
          </ListItem>
          <Divider component="li" />
        </span>
      })}
    </List> */}
    <Grid container justify="space-between" sx={{ mt: 2, mb: 0, }}>
      <Typography sx={{ textAlign: 'left' }} variant="h1" component="h2">
        Billing Summary
      </Typography>
    </Grid>
    {txInfo?.availableDiscount && <Alert
      severity="info"
      sx={{ width: '100%', alignItems: 'center', backgroundColor: "#E4F1FF" }}>
      <Typography sx={{ color: "#073B72" }} >
        To get a {txInfo?.availableDiscount?.percentage}% discount, your cart value must be at least {txInfo?.availableDiscount?.minAmount[region]?.currency} {txInfo?.availableDiscount?.minAmount[region]?.amount}
      </Typography>
    </Alert>}
    <List sx={style}>
      <span>
        <ListItem
          disablePadding
          secondaryAction={
            <Typography sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}>

              {process.env.REACT_APP_DISCOUNT_VALUE ? <>
                <Tooltip title="Limited time offer" sx={{ fontSize: '2.6rem' }} >
                  <InfoOutlined sx={{ fontSize: '1.6rem', marginRight: '0.2em' }} />
                </Tooltip>
                <span
                  style={{
                    textDecoration: 'line-through', marginRight: '0.5em'
                  }}
                >
                  {CURRENCY_SYMBOL} {process.env.REACT_APP_DISCOUNT_VALUE}</span>
              </> : null}
              {txInfo?.totalAmountPaid ? `${CURRENCY_SYMBOL} ${txInfo?.totalAmountPaid}` : txInfo?.total ? `${CURRENCY_SYMBOL} ${txInfo?.total}` : '-'}
            </Typography>
          }>
          <ListItemText sx={{ marginLeft: 1, fontWeight: '400' }} primary={"Total"} />
        </ListItem>
        <Divider component="li" />
      </span>
      {txInfo?.discount && txInfo?.discount?.amount && <span>
        <ListItem
          disablePadding
          secondaryAction={
            <Typography sx={{ fontWeight: '500', color: '#239D56' }}>
              {txInfo?.discount?.amount ? `- ${CURRENCY_SYMBOL} ${txInfo.discount?.amount}` : '-'}
            </Typography>
          }>

          <ListItemText sx={{ marginLeft: 1, fontWeight: '400', }} primary={`Extra Discount`} />
        </ListItem>
        <Divider component="li" />
      </span>}
      {txInfo?.gst > 0 && gstPercentage !== null && <span>
        <Tooltip
          title={`This transaction incurs an ${gstPercentage} Goods and Services Tax (GST) charge as per current regulations.`}
        >
          <ListItem
            disablePadding
            secondaryAction={
              <Typography sx={{ fontWeight: '500' }}>
                {txInfo.gst ? `${CURRENCY_SYMBOL} ${txInfo.gst}` : '-'}
              </Typography>
            }>

            <ListItemText sx={{ marginLeft: 1, fontWeight: '400', textDecoration: 'underline' }} primary={`GST Charges (${gstPercentage})`} />
          </ListItem>
        </Tooltip>
        <Divider component="li" />
      </span>}
      <span>
        <ListItem
          disablePadding
          secondaryAction={
            <Stack direction="row" spacing={1}>
              {txInfo?.discount && txInfo?.discount?.amount && <Typography sx={{ fontWeight: '600', textDecoration: 'line-through', color: '#8F8F92' }} >
                {`${CURRENCY_SYMBOL} ${txInfo.toPay + txInfo?.discount?.amount}`}
              </Typography>}
              <Typography sx={{ fontWeight: 'bold' }} >
                {txInfo.toPay ? `${CURRENCY_SYMBOL} ${txInfo.toPay}` : '-'}
              </Typography>
            </Stack>
          }>

          <ListItemText sx={{ marginLeft: 1, fontWeight: '700' }}
            secondaryTypographyProps={{ fontWeight: 'bold', color: '#8F8F92', lineHeight: 0.5 }}
            primary={txHistoryView ? "Amount Paid" : "To Pay"}
            secondary={'Incl. all taxes and charges'} />
        </ListItem>
        <Divider component="li" />
      </span>
      {txInfo?.discount && txInfo?.discount?.amount && <span>
        <ListItem
          disablePadding
          sx={{ backgroundColor: '#F4FFEE', justifyContent: 'center', alignItems: 'center' }}>
          <Typography sx={{ fontWeight: 'bold', color: '#239D56' }}>
            {`You are saving ${CURRENCY_SYMBOL} ${txInfo?.discount?.amount}`}
          </Typography>
        </ListItem>
      </span>}
    </List>
    {txHistoryView && <><Grid container justify="space-between" sx={{ mt: 2, mb: 0, }}>
      <Typography sx={{ textAlign: 'left' }} variant="h1" component="h2">
        ID Details
      </Typography>
    </Grid>
      <List sx={style}>
        <span>
          <ListItem
            sx={{ cursor: 'pointer' }}
            onClick={() => pasteToClipboard(txInfo?.paymentId)}
            disablePadding
            secondaryAction={
              <Typography sx={{ fontWeight: '500' }}>
                <ContentCopy sx={{ fontSize: '1.6rem' }} /> {txInfo?.paymentId}
              </Typography>
            }>
            <ListItemText sx={{ marginLeft: 1, fontWeight: '400' }} primary={"Payment ID"} />
          </ListItem>
          <Divider component="li" />
        </span>
        <span>
          <ListItem
            sx={{ cursor: 'pointer' }}
            onClick={() => pasteToClipboard(txInfo?.transactionId)}
            disablePadding
            secondaryAction={
              <Typography sx={{ fontWeight: '500' }}>
                <ContentCopy sx={{ fontSize: '1.6rem' }} /> {txInfo?.transactionId}
              </Typography>
            }>
            <ListItemText sx={{ marginLeft: 1, fontWeight: '400' }} primary={"Transaction ID"} />
          </ListItem>
          <Divider component="li" />
        </span>
      </List></>}


    {txInfo?.refundDetails?.length > 0 ?
      <><Grid container justify="space-between" sx={{ mt: 2, mb: 0, }}>
        <Typography sx={{ textAlign: 'left' }} variant="h1" component="h2">
          Refunds
        </Typography>
      </Grid>
        <List dense sx={style}>
          {
            txInfo?.refundDetails
              ?.sort((a, b) => b.createdAt - a.createdAt)?.
              map((refund, i) => <>
                <ListItem
                  secondaryAction={
                    <Grid container direction="column"
                      justifyContent={"center"} alignItems={"center"}>
                      <Typography sx={{
                        fontWeight: 'bold',
                        display: 'flex', flexDirection: 'row'
                      }}

                      >
                        {CURRENCY_SYMBOL} {refund?.amount}
                      </Typography>
                      <Chip
                        color={states[refund?.status?.toUpperCase()]?.theme}
                        variant="outlined"
                        sx={{
                          '& .MuiChip-label': { fontSize: '1rem', fontWeight: 'bold' }
                        }}
                        size="small"
                        icon={states[refund?.status?.toUpperCase()]?.icon}
                        label={states[refund?.status?.toUpperCase()]?.title} />
                    </Grid>
                  }>
                  <ListItemText
                    primary={refund?.reason}
                    primaryTypographyProps={{ sx: { fontWeight: 'bold', } }}
                    secondary={displayTime(refund?.createdAt, 'DD-MMM-YYYY,  hh:mm:ss:A')}
                  />

                </ListItem>
                <List sx={{ ...style, width: '95%', margin: '1em auto' }}>
                  <span>
                    <ListItem
                      sx={{ cursor: 'pointer' }}
                      onClick={() => pasteToClipboard(refund?.refundId
                      )}
                      disablePadding
                      secondaryAction={
                        <Typography sx={{ fontWeight: '500' }}>
                          <ContentCopy sx={{ fontSize: '1.6rem' }} /> {refund?.refundId
                          }
                        </Typography>
                      }>
                      <ListItemText sx={{ marginLeft: 1, fontWeight: '400' }} primary={"Refund ID"} />
                    </ListItem>
                    <Divider component="li" />
                  </span>
                  <span>
                    <ListItem
                      sx={{ cursor: 'pointer' }}
                      onClick={() => pasteToClipboard(refund?._id
                      )}
                      disablePadding
                      secondaryAction={
                        <Typography sx={{ fontWeight: '500' }}>
                          <ContentCopy sx={{ fontSize: '1.6rem' }} /> {refund?._id
                          }
                        </Typography>
                      }>
                      <ListItemText sx={{ marginLeft: 1, fontWeight: '400' }} primary={"Transaction ID"} />
                    </ListItem>
                    <Divider component="li" />
                  </span>
                </List>
                <Divider sx={{ width: '100%' }} />
              </>
              )}

        </List></>
      : null}
    <BottomComponent>
      {!txHistoryView &&
        txInfo?.disclaimer?.length > 0 && <Alert
          severity="info"
          sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <Typography>
            {txInfo?.disclaimer}
          </Typography>
        </Alert>
      }
      {document.URL.includes('transactionHistory=true') ? null : <LoadingButton
        loading={loading}
        onClick={async () => initiatePayment()}
        variant="contained"
        sx={{ fontWeight: 600, margin: '1em 0' }}
        id="pay"
      >
        Continue to Pay {CURRENCY_SYMBOL} {txInfo?.toPay}
      </LoadingButton>}
      {paymentStatus == 'Pending' && paymentPollTimeout.current > 0 && <Typography>Please wait while we fetch the payment status</Typography>}

    </BottomComponent>
  </SimpleBackground>
};

export default Payment;
