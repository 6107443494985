export const routes = {
  login: '/api/1.0/profile/login',
  refreshToken: '/api/1.0/profile/login/get-token',
  profile: '/api/1.0/profile',
  credentials: '/api/1.0/credentials',
  otp: (txId) => `/api/1.0/profile/otp/${txId}/verify`,
  uploadDocGetAttributes: (type) => `/api/1.0/credentials/documents/${type}`,
  putVcInWallet: (type) => `/api/1.0/credentials/documents/${type}/receive`,
  getAadhaar: '/api/1.0/credentials/digilocker/aadhaar',
  putVcInWalletFromDigilocker: (uri) => `/api/1.0/credentials/digilocker/${uri}`,
  getDigilockerToken: '/api/1.0/digilocker/get-token',
  getIssuedDocsFromDigilocker: '/api/1.0/digilocker/proxy/oauth2/2/files/issued',
  pullDocumentInDigilocker: '/oauth2/1/pull/pulldocument',
  getCredentialInvitation: '/api/2.0/credential-batch',
  oobInvitation: '/api/1.0/oob/receive',
  aadharCall: '/api/1.0/credential/ekyc/aadhaar',
  aadhaarOtpCall: '/api/1.0/credential/ekyc/aadhaar/verify',
  masterDigilocker: '/api/1.0/credential/digilocker',
  oAuth: (responseType = 'code', clientId = 'test1', redirectUri, scope, state) => `/api/1.0/verify?response_type=${responseType}&client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUri}`,

  masterVerify: 'https://ssp.aashishprasad-c.repl.co/ekyc',
  getEkycFormById: '/api/1.0/transactions/info',
  startEkycFormById: '/api/1.0/transactions/process',
  createTransactionsProcess: '/api/1.0/transactions',
  submitToForm: '/api/1.0/credentials/receive',
  shareToForm: 'ekyc/share',
  captcha: (doc) => `/web-wallet/api/1.0/verifications/${doc}/verification-captcha`,
  getAttributes: '/api/1.0/credentials/verify',
  faceMatch: (doc) => `/api/1.0/transactions/process/${doc}`,
  getOrgId: '/api/1.0/verifier',
  files: '/api/1.0/wallet/files',
  getProfessions: '/api/1.0/professions',
  skipTxn: '/api/1.0/transactions/process/mark-as-skip',
  getAllForms: '/api/1.0/transactions/candidate-transaction?seed=0&count=1000',
  getAllFormsOfEKYC: '/api/1.0/ekyc-forms/public?seed=0&count=1000',
  dataInsufficiency: '/api/1.0/bgv/insufficiency',
  getConfigs: '/web-wallet/api/1.0/verification-configs/info',
  getDataOfCompletedForm: (doc, orgId, txnId) => `/api/1.0/transactions/${doc}/info?orgId=${orgId}&txnId=${txnId}`,
  addProfileVerificationData: '/api/1.0/profile/verification-detail',
  deleteVerification: '/api/1.0/bgv',
  editVerification: '/api/1.0/bgv',
  notifications: '/api/1.0/notification',
  updateBasicDetails: '/api/1.0/transactions/update-identification',
  hash: '/api/1.0/profile/tawkto-hash',
  pincode: '/api/1.0/pincode',
  getPreStartQuestionList: '/api/1.0/purposes/user',
  submitAnswersToPreStartQuestionList: '/api/1.0/transactions/user-response'
};


export const apiEndpoints = {
  BGV: () => `${process.env.REACT_APP_EKYC_URL}/api/1.0/bgv`,
  WEB_WALLET: (x) => `${process.env.REACT_APP_WEB_WALLET_URL}/api/1.0/credentials/verify/${x}`, // /din,
  AADHAAR_ONE: () => `${process.env.REACT_APP_EKYC_URL}/web-wallet/api/1.0/verifications/otp/aadhaar`,
  AADHAAR_TWO: () => `${process.env.REACT_APP_ISSUER_TRENTIAL_URL}/api/1.0/profile/aadhaar`,
  AADHAAR_THREE: () => `${process.env.REACT_APP_BASE_URL}/api/1.0/profile/verification-detail`,
  INSTITUTE: () => `${process.env.REACT_APP_EKYC_URL}/api/1.0/institutes`,

};
