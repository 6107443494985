import { Box, Divider, IconButton, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import { Fragment, useEffect, useRef } from 'react';
import { TOP_BAR_LINKS } from '../constants';
import wesLogo from '../../../../../../Assets/wes-login/wes-logo.svg';


function SideBar({ setOpen }) {
  const timeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  return (
    <Box sx={{ width: 250 }} role="presentation">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: '1.25rem 1.1rem',
          borderBottom: '1px solid',
          borderColor: 'border.main'
        }}>
        <img src={wesLogo} alt="trential" style={{height: '3rem', width: 'auto'}} />
        <IconButton sx={{ p: 0 }} onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <List>
        {TOP_BAR_LINKS.map((row) => (
          <Fragment key={row.label}>
            {row.showTopBorder && <Divider sx={{ m: '1rem 0' }} />}
            <ListItem disablePadding>
              <ListItemButton onClick={() => {
                if (timeoutRef.current) {
                  clearTimeout(timeoutRef.current);
                }
                timeoutRef.current = setTimeout(() => {
                  document.getElementById(row.elementId)?.scrollIntoView({behavior: 'smooth'});
                }, 100);
                setOpen(false);
              }}>
                <ListItemText
                  primary={
                    <Typography className='wes-auth-caption' sx={{color: '#000 !important', fontWeight: '500'}}>
                      {row.label}
                    </Typography>
                  } sx={{ fontWeight: '500' }} />
              </ListItemButton>
            </ListItem>
          </Fragment>
        ))}
      </List>
    </Box>
  );
}

export default SideBar;
