import { Box, Button, Checkbox, CircularProgress, FormControlLabel, InputAdornment, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import OTPInput from 'react-otp-input';
import { Link } from 'react-router-dom';
import trentialLogo from '../../../../../../Assets/wes-login/trential-dark-logo.svg';
import CountryInput from '../../../../../../Components/UI/CountryInput';
import CustomTextField from '../../../../../../Components/UI/Input/customTextField';
import { getCountryNameCode } from '../../../../../../Config/api';
import footerStyles from '../../footer/styles';
import useAuth from './hooks/use-auth';

const onlyNumbersRegex = /^\d+$/;
const minDigitsInPhoneNumber = 4;

function LoginForm() {
  const theme = useTheme();
  const smallerThanSMScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const largerThanXLScreen = useMediaQuery(theme.breakpoints.up('xl'));
  const { states, handlers, misc } = useAuth();
  const [loadingCountry, setLoadingCountry] = React.useState(true);
  const {
    phNo,
    setPhNo,
    countryCode,
    setCountryCode,
    conditionsAgreed,
    setConditionsAgreed,
    step,
    errors,
    setErrors,
    isLoading,
    otp,
    setOtp,
    otpTimeoutSeconds,
    flagId,
    setFlagId
  } = states;
  const {
    getTimeoutTime,
    startReqOTP,
    handleResendOtp,
    handleEnterKeyPress,
    submitOtp
  } = handlers;
  const {
    phoneRef,
  } = misc;

  const getIPData = async () => {
    const res = await getCountryNameCode();
    if (res) {
      const countryCallingCode = res.country_calling_code;
      const countryCode = res.country_code;
      if (countryCallingCode && countryCode) {
        setCountryCode(countryCallingCode);
        setFlagId(countryCode);
      };
    };
    setLoadingCountry(false);
  };

  React.useEffect(() => {
    getIPData();
  }, []);

  return (
    <Box sx={{height: '100%', flex: 1, padding: {xs: '1.6rem', md: '2rem', blg: '2.6rem'}, display: 'flex', flexFlow: 'column', justifyContent: 'space-between', rowGap: '1.5rem'}}>
      <Box sx={{display: 'flex', flexFlow: 'column', rowGap: '1.2rem'}}>
        <Typography className='wes-auth-login-form-title'>
    Sign in with OTP
        </Typography>
        <Typography className='wes-auth-caption' sx={{color: '#60656C !important', fontWeight: '500'}}>
          {step === 0 ? 'Enter your mobile number' : 'Enter the OTP'}
        </Typography>
        {
            step === 0 ?
              <CustomTextField
                ref={phoneRef}
                required
                fullWidth autoFocus
                sx={{
                  '& .MuiInputBase-root': {
                    paddingLeft: 0,
                  }, '& .MuiInputBase-input': {
                    borderLeft: `1px solid rgba(0, 0, 0, 0.20)`,
                    borderRadius: 0,
                    paddingLeft: '0.2em'
                  }
                }}
                error={errors?.phNo?.length > 0}
                helperText={errors?.phNo?.length > 0 && errors?.phNo !== 'required' && errors?.phNo}
                id="PhoneNumber"
                value={phNo}
                onChange={(event) => {
                  event.target.value = event.target.value.trimStart();
                  if (event?.target?.value !== '' && !onlyNumbersRegex.test(event.target.value)) return;
                  if (event.target.value?.length <= 12) {
                    setPhNo(event.target.value);
                  }
                  if (errors?.phNo) deleteFromErrors(errors, setErrors, 'phNo');
                }}
                InputProps={{
                  startAdornment: loadingCountry ? <CircularProgress sx={{ p: 1 }} /> : <InputAdornment
                    position="start" sx={{ marginRight: '0px' }}>
                    <CountryInput
                      countryCode={countryCode} setCountryCode={setCountryCode}
                      flagId={flagId}
                      setFlagId={setFlagId}
                      phoneRef={phoneRef} />
                  </InputAdornment>
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && conditionsAgreed && phNo?.length >= minDigitsInPhoneNumber && !isLoading.requestOTP) {
                    startReqOTP();
                  }
                }}
              /> :
              <Box>
                <OTPInput
                  value={otp}
                  inputType={'tel'}
                  onChange={(val) => {
                    setOtp(val);
                    if (errors?.otp) deleteFromErrors(errors, setErrors, 'otp');
                  }}
                  numInputs={6}
                  renderSeparator={(index) =>
                    index === 2 ?
                      <div style={{ width: '1.7rem', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'black', height: '1px', margin: '0 2rem' }} /> :
                      <div style={{ width: '2rem', marginRight: '.4rem' }}></div>
                  }
                  renderInput={(props) => <input {...props} onKeyPress={handleEnterKeyPress} />}
                  inputStyle={{
                    height: smallerThanSMScreen ? '7rem' : '8.8rem',
                    width: '70%',
                    borderRadius: '.4rem',
                    border: errors?.otp?.length > 0 ? '1.5px solid #ff0000' : '1.2px solid #eee',
                    backgroundColor: '#f3f8ff',
                    fontSize: largerThanXLScreen ? '3.5rem' : smallerThanSMScreen ? '2.5rem' : '3rem'
                  }}
                  shouldAutoFocus
                />
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '2rem' }}>
                  {
                    otpTimeoutSeconds === 0 ?
                      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 0 }}>
                        <Typography className='wes-auth-caption' sx={{color: '#60656C !important', fontWeight: '500'}}>
                          {'Haven\'t received OTP?'}
                        </Typography>
                        <Button
                          sx={{ fontSize: { xs: '1.3rem', xl: '1.85rem' }, fontWeight: '700' }}
                          onClick={handleResendOtp}
                        >
                          Resend OTP
                        </Button>
                      </Box> :
                      <>
                        <Typography sx={{ fontWeight: '400', mr: '1rem', fontSize: { xs: '1.25rem', xl: '1.85rem' } }}>
                          Resend OTP in:
                          {' '}
                        </Typography>
                        <Typography sx={{ fontSize: { xs: '1.25rem', xl: '1.85rem' }, fontWeight: '700' }}>
                          {getTimeoutTime()}
                        </Typography>
                      </>
                  }
                </Box>
              </Box>
        }
      </Box>
      <Box sx={{display: 'flex', flexFlow: 'column', rowGap: '1.5rem'}}>
        <FormControlLabel
          control={
            <Checkbox
              size="medium"
              checked={conditionsAgreed}
              onChange={() => setConditionsAgreed((prev) => !prev)}
            />
          }
          label={<Typography className='wes-auth-caption' sx={{color: '#60656C !important', fontWeight: '500'}}>
            {'I agree to the '}
            <Button
              disableRipple disableFocusRipple component={Link} to={'/terms'} className='wes-auth-caption'
              sx={{...footerStyles.linkBtn, color: '#1C43C3 !important'}}>
                 terms and conditions
            </Button>
          </Typography>}
        />
        <Button
          sx={{ 'width': '100%', 'backgroundColor': '#1C43C3', 'fontSize': '1.7rem', 'p': '1rem !important'}}
          onClick={() => {
            if (step === 0) return startReqOTP();
            submitOtp();
          }}
          variant='contained'
          disabled={!conditionsAgreed || (step === 0 && phNo?.length < minDigitsInPhoneNumber) || (step === 1 && otp?.length < 6) || isLoading?.requestOTP || isLoading?.submitOTP}
          startIcon={(isLoading?.requestOTP || isLoading?.submitOTP) && <CircularProgress style={{ color: 'grey', height: '1.3rem', width: '1.3rem' }} />}
        >
          {step === 0 ? 'Get OTP' : 'Continue'}
        </Button>
        <Box sx={{display: 'flex', justifyContent: 'center', mt: '1rem'}}>
          <Box sx={{display: 'flex', columnGap: '.5rem'}}>
            <Typography className='wes-auth-caption' sx={{color: '#60656C !important', fontWeight: '400'}}>
        Powered by
            </Typography>
            <Box component={'img'} src={trentialLogo} />
          </Box>
        </Box>

      </Box>
    </Box>
  );
}

export default LoginForm;
