import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Grid } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import MarkdownWrapper from '../../Components/UI/markdownWrapper';
import { configs } from './configs';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: '1px solid #A0A08E',
  borderRadius: '8px',
  padding: '1em',
  margin: '1em',

}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={(
      <ArrowDropDownIcon sx={{
        fontSize: '1.8rem',
      }} />
    )}
    {...props}
  />
))(({ theme }) => ({
  'flexDirection': 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // padding: theme.spacing(2),
  // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
const answerFontColor = '#000';
export default function Faq() {
  const [expanded, setExpanded] = React.useState('');
  // document.title = `FAQ`;
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Grid sx={{ padding: '2px' }}>
      <h2>
        Frequently Asked Questions
      </h2>

      {configs?.faq(answerFontColor)?.map((f, i) => (
        <Accordion
          expanded={expanded === `panel${i}`}
          onChange={handleChange(`panel${i}`)}
        >
          <AccordionSummary aria-controls={`panel${i}d-content`} id={`panel${i}d-header`}>
            <Typography sx={{ fontWeight: '600' }}>
              {f.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ ml: '1.85rem' }}>
            <Typography>
              <MarkdownWrapper>
                {f.answer}
              </MarkdownWrapper>
            </Typography>

          </AccordionDetails>
        </Accordion>
      ))}

      <Accordion
        expanded={expanded === 'panel8'}
        onChange={handleChange('panel8')}
      >
        <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
          <Typography sx={{ fontWeight: '600' }}>
            Is it possible to submit additional documents for verification after making a payment?
            {' '}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Absolutely, you have the option to submit more documents after you’ve made a payment. Here’s how you can do it:


          </Typography>
          <ol>
            <Typography component="li" variant="body1">
              Log into your account.
              {' '}
            </Typography>
            <Typography component="li" variant="body1">
              Navigate to the Verification Form and then to the Required Verification section.
              {' '}
            </Typography>
            <Typography component="li" variant="body1">
              Begin uploading your documents.
            </Typography>
            <Typography component="li" variant="body1">
              Once you’ve finished uploading, you’ll need to make a payment for the extra verifications. To do this, click on the ‘Click to Pay’ button located at the bottom of the page.

            </Typography>
            <Typography component="li" variant="body1">
              Choose your preferred payment method and complete the transaction.
            </Typography>
            <Typography component="li" variant="body1">
              After the payment is successful, the newly added documents will be sent for verification.
            </Typography>

            {' '}
          </ol>
          <Typography>
            Please note: If you add documents after making a payment, the verification process will take longer. This is because additional time is needed to verify the new documents.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
