import { Typography } from '@mui/material';

export const customConfigFaq = (org, fontColor) => {
  switch (org) {
    case 'nsdc': return [
      // {
      //   question: 'How long will the verification process take?',
      //   answer: `<span style='color:${fontColor}'>After the successful submission of the documents, it may take 5 to 10 working days to get them verified by your institution. In case the documents submitted from your side are incomplete, then the verification time will increase.</span>`
      // },
      {
        question: 'How long does it take to get my docs verified and sent to WES?',
        answer: `<span style='color:${fontColor}'>After the successful submission of the documents and successful payment, it may take 5 to 14 working days (this excludes weekend and public holidays) to get them verified by your institution. In case the documents submitted from your side are incomplete, then the verification time will increase.</span>`
      },
      {
        question: 'How soon will I see my WES account updated after the file is shared with WES?',
        answer: `<span style='color:${fontColor}'>Once sent to WES, verification documents will be reflected in up to 2 working days on the <a style='color:${fontColor}' target='_blank' rel='noopener noreferrer' href='https://applications.wes.org/createaccount/login/login/'>WES My Account</a>.</span>`,
        includesHTML: true,
      },
      {
        question: 'Why is the verification fee different for different candidates?',
        answer: `<span style='color:${fontColor}'>The verification fee may vary from individual to individual as it depends on the number of documents submitted, as well as on the institution that issued them.</span>`
      }, {
        question: 'What should I do if I don’t receive any of the OTPs?',
        answer: `<span style='color:${fontColor}'>Your One Time Password (OTP) is sent to the same email ID used on your WES application. This may take up to 5 minutes.<br/><br/>     If you have not received your OTP, please check your Spam/Junk folders before trying again. Please ensure you have entered the correct WES reference number. If the email ID in your WES application is incorrect, kindly contact WES Customer Support through the following link: <a style='color: #fff' target="_blank" rel="noopener noreferrer" href='https://www.wes.org/contact-us/'> https://www.wes.org/contact-us/ </a></span>`
      },
      {
        question: 'How many attempts are there to get the OTPs? In case I fail in all the attempts, what should I do next?',
        answer: `<span style='color:${fontColor}'><ul>
            <li>
              You will be allowed to request up to 3 OTPs.
            </li>
            <li>
              On 3 unsuccessful attempts, you will be blocked for 2 hours. You may try again once this period has expired.
            </li>
            <li>
              In case you do not receive the OTP over email, check the Spam/Junk
              folders and check for any email from
              <strong>
                noreply-nsdctrust@nsdcindia.org
              </strong>
            </li>
            <li>
              In case you receive multiple OTPs, use the OTP received most recently. The older OTPs will not work.
            </li>
          </ul></span>` },
      {
        question: 'I don\'t want to complete all verifications, but I can\'t see the payment button after completing one verification',
        answer: `<span style='color:${fontColor}'>You need to mark them as skipped by clicking the verification and then clicking skip, once done the form will be complete and you will be able to see the payment button</span>`
      },
      {
        question: 'How is the verification fee being calculated?',
        answer: `<span style='color:${fontColor}'>The verification fee varies depending on the number of verifications you have added, it also depends on your graduating University/College.</span>`,
        includesHTML: true,
      },
      {
        question: 'What should I do if I don\'t receive the signup or login OTP on my phone number',
        answer: `<span style='color:${fontColor}'>Verify that the number you entered is correct. If it is, please wait a few minutes. For non-Indian numbers, check your WhatsApp messages for the OTP</span>`
      }, {
        question: 'How will WES receive my documents?',
        answer: `<span style='color:${fontColor}'>Once your documents are verified, we send a report directly to WES for credential evaluation, you will receive a completion email and SMS once your documents are verified. For credential evaluation updates please contact WES support</span>`
      },
      {
        question: 'Difference between NSDC and WES?',
        answer: `<span style='color:${fontColor}'>NSDC has partnered with WES to digitalize the document verification part of
        the credential evaluation process. We verify the student's educational
        documents and send the verified documents directly to WES for evaluation.
        WES then evaluates the document to generate the final evaluation report.</span>` },
      {
        question: 'Do I need to contact my college or pay them any fee?',
        answer: `<span style='color:${fontColor}'>No, the charge you pay to NSDC includes that fee and you do not need to
        pay the university any other amount</span>`
      },
      {
        question: 'Why does NSDC charge a separate fee?',
        answer: `<span style='color:${fontColor}'>NSDC charges for the document verification part which is separate from the
fee WES charges for credential evaluation</span>`
      },
      {
        question: 'What should I do if I realize there is an error in the documents I uploaded?',
        answer: `<span style='color:${fontColor}'>If you haven't completed the payment, you can simply click the verification
            and re-upload the data and documents, Incase the payment is completed you
            can contact us via the chatbot or you can write to us on our email
            <b>trust.support@nsdcindia.org</b></span>`
      },
      {
        question: 'How can I check the progress of my verification request?',
        answer: `<span style='color:${fontColor}'>You will be notified on your contact email and phone number via
          SMS/WhatsApp for any updates on your application, you can also use the
          chatbot which appears post login to know the status of your application</span>`
      },
      {
        question: 'What happens if my payment fails, or I encounter issues during payment?',
        answer: `<span style='color:${fontColor}'>Check if the details entered are correct and try again, if it still doesn't work, try
        another payment method, incase the payment gateway is down please try
        after sometime.</span>`
      },
      {
        question: 'What should I do if I experience technical issues with the platform?',
        answer: `<span style='color:${fontColor}'>Incase you are experiencing any technical issues you can contact us via the
            chatbot or you can write to us on our email
            <b>trust.support@nsdcindia.org</b></span>`
      },
      {
        question: 'How do I contact customer support?',
        answer: `<span style='color:${fontColor}'>You can login to our portal and use the chatbot to contact support team or you
            can write to us on our email
            <b>trust.support@nsdcindia.org</b></span>`
      }
    ];
    case 'ECE': return [{
      question: 'How long will the verification process take?',
      answer: `After the successful submission of the documents, it may take 5 to 12 working days to get them verified by your institution. In case the documents submitted from your side are incomplete, then the verification time will increase. `
    },
    {
      question: 'How will does my verification reach to ECE? ',
      answer: 'You will be notified via SMS/Email on successful verification, and the report will be instantly uploaded to the ECE platform. No manual intervention is required from your side. '
    },
    {
      question: 'How long will it take to prepare an evaluation report after ECE receives the verification?',
      answer: `ECE's order processing time is an average of 3-5 business days from the date we receive all required information. Processing time can vary and does not include delivery time, weekends, or holidays.
      <br/>
      If you have a deadline, ECE offers a guaranteed **5 Business Day Rush Service ($80)** and delivery options. More information is available on the <a href="https://www.ece.org/ECE/Credential-Evaluations/US-Institutions/Services-and-Fees">Services and Fees</a> page of this website. `,
      includesHTML: true,
    },
    {
      question: 'Why is the verification fee different for different candidates? ',
      answer: 'The verification fee may vary from individual to individual as it depends on the number of documents submitted, as well as on the institution that issued them. '
    }];
    case 'GCE': return [{
      question: 'How long will the verification process take?',
      answer: `After the successful submission of the documents, it may take 5 to 12 working days to get them verified by your institution. In case the documents submitted from your side are incomplete, then the verification time will increase. `
    },
    {
      question: 'How will does my verification reach to GCE? ',
      answer: 'You will be notified via SMS/Email on successful verification, and the report will be instantly uploaded to the GCE platform. No manual intervention is required from your side. '
    },
    {
      question: 'How soon will I see my GCE account updated after the file is shared with GCE? ',
      answer: `Once sent to GCE, verification documents will be reflected in up to 2 working days on GCE portal. `,
      includesHTML: true,
    },
    {
      question: 'Why is the verification fee different for different candidates? ',
      answer: 'The verification fee may vary from individual to individual as it depends on the number of documents submitted, as well as on the institution that issued them. '
    }];
    default: return [];
  }
};

export const customConfigInstructions = (org) => {
  switch (org) {
    case 'nsdc': return [
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Enter "WES Number"
          </Typography>
        ),
        description: <ul>
          <Typography component="li" variant="body1">
            Post login, click the "User Details"  and input your "WES Number"
            {' '}
          </Typography>
        </ul>,
      },
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Email Authentication Via OTP
          </Typography>
        ),
        description: <ul>
          <Typography component="li" variant="body1">
            You will receive a 6-digit One-time-password on email registered with WES.
            {' '}
          </Typography>
        </ul>,
      },
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Identity Verification - Aadhaar eKYC
          </Typography>
        ),
        description: <ul>
          <Typography component="li" variant="body1">
            Enter your 12-digit Aadhaar Number to receive OTP on your registered phone number
            {' '}
          </Typography>
          <Typography component="li" variant="body1">
            Enter the OTP received to verify your Aadhaar
            {' '}
          </Typography>
        </ul>,
      },
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            10th Marksheet Upload (optional)
          </Typography>

        ),
        description: <ul>
          <Typography component="li" variant="body1">
            Enter the details required under the  10th-Certificate section.
            {' '}
          </Typography>
          <Typography component="li" variant="body1">
            Click on the option for uploading your 10th-grade mark sheet.
            {' '}
          </Typography>
          <Typography component="li" variant="body1">
            Upload the scan-copy of your 10th-grade marksheet in PDF Format.
            {' '}
          </Typography>
        </ul>,
      },
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            12th Marksheet Upload (optional)
          </Typography>
        ),
        description: <ul>
          <Typography component="li" variant="body1">
            Similarly, for 12th Certificate enter the details required
            {' '}
          </Typography>
          <Typography component="li" variant="body1">
            Select the file containing your 12th-grade marks and upload it.
            {' '}
          </Typography>
        </ul>,
      },
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Degree Upload (Provisional/Final Degree Certificate and All Semester Marksheets.)
          </Typography>
        ),
        description: <ul>
          <Typography component="li" variant="body1">
            Proceed to the section for uploading proof of your degree.
            {' '}
          </Typography>
          <Typography component="li" variant="body1">
            Upload a copy of your degree certificate and marksheet for all semesters.
            {' '}
          </Typography>
        </ul>,
      },
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Add Multiple Degrees (If Applicable)
          </Typography>
        ),
        description: <ul>
          <Typography component="li" variant="body1">
            If you have multiple degrees to add, find the option to "Add Another Degree."
            {' '}
          </Typography>
          <Typography component="li" variant="body1">
            Repeat the process by uploading documents for each additional degree.
            {' '}
          </Typography>
        </ul>,
      },
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Review Uploaded Documents
          </Typography>
        ),
        description: <ul>
          <Typography component="li" variant="body1">
            Ensure that all uploaded documents are accurate and legible.
            {' '}
          </Typography>
          <Typography component="li" variant="body1">
            Review the information associated with each degree.
            {' '}
          </Typography>
        </ul>,
      },
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Proceed to Payment
          </Typography>
        ),
        description: <ul>
          <Typography component="li" variant="body1">
            After successfully uploading all required documents, click the "Pay" button at the bottom
            {' '}
          </Typography>
          <Typography component="li" variant="body1">
            Follow the prompts to complete the payment process
            {' '}
          </Typography>
          <Typography component="li" variant="body1">
            The prices may vary from individual to individual as it depends on number of documents submitted as well as the graduating institute.
          </Typography>
        </ul>,
      },
    ];
    case 'ECE': return [
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Step 1: Login to your web-wallet
          </Typography>
        ),
        description: <div>
          <ul>
            <Typography component="li" variant="body1">
              Enter your mobile number to receive the OTP
            </Typography>
            <Typography component="li" variant="body1">
              Enter the OTP received on your mobile number via SMS or WhatsApp
            </Typography>
          </ul>
          {' '}
          <Typography variant="body1">
            Note - This can be any mobile number on which you want to receive future communications
          </Typography>
        </div>,
      },
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Step 2: Identity Verification - Aadhaar or Passport
          </Typography>
        ),
        description: <div>
          <Typography variant="body1">
            Note - If you are an Indian citizen then select aadhaar to verify yourself, incase you are a foreign national or your mobile number is not linked to your aadhaar then you can verify using passport
            {' '}
          </Typography>
          <ul>
            <Typography component="li" variant="body1">
              Enter your 12-digit Aadhaar Number to receive OTP on your Aadhaar registered phone number.
            </Typography>
            <Typography variant="body1">
              OR
              {' '}
            </Typography>
            <Typography component="li" variant="body1">
              Scan your passport’s Front Page using webcam/mobile phone
            </Typography>
            <Typography component="li" variant="body1">
              Once verified you will proceed to the next step
            </Typography>
          </ul>
        </div>,
      },
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Step 3: Validate your  "ECE Reference Number"
          </Typography>
        ),
        description: <ul>
          <Typography component="li" variant="body1">
            Click the "User Details" and input your "ECE Number".
          </Typography>
          <Typography component="li" variant="body1">
            You will receive a 6-digit One-time-password on email registered with ECE.
            {' '}
          </Typography>
          {' '}
          <Typography component="li" variant="body1">
            Enter the OTP to proceed
          </Typography>
        </ul>,
      },
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Step 4:  Upload your dcouments
          </Typography>

        ),
        description: <div>
          <Typography variant="body1">
            Note -
            {' '}
          </Typography>
          <ol>
            <Typography component="li" variant="body1">
              You need to complete at least one of the three education checks
              {' '}
              <b>
                1).10th   2).12th   3).Degree.
              </b>
            </Typography>
            <Typography component="li" variant="body1">
              Please submit documentation for the education you wish to have evaluated.  If you completed post-secondary (university) level study, it is generally not necessary to submit secondary level documents unless you have ordered a High School and University Level Course by Course report. Ask your U.S. institution if a report of your high school is needed.
            </Typography>
            <Typography component="li" variant="body1">
              You only need to upload the Standard X (Grade 10) Certificate (or equivalent) if you need a detailed course listing or grade average of your secondary education.
            </Typography>
            <Typography component="li" variant="body1">
              The rest should be marked as skipped by clicking the verification and the clicking the skip button
            </Typography>
            <Typography component="li" variant="body1">
              Make sure that the uploaded documents are of good quality with the details clearly visible in order to process the verifications faster
            </Typography>
          </ol>
        </div>,
      },
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Step 4a:  Upload 10th Grade Marksheet (optional)
          </Typography>
        ),
        description: <ul>
          <Typography component="li" variant="body1">
            Enter the details required under the 10th-Certificate section.
            {' '}
          </Typography>
          <Typography component="li" variant="body1">
            Click on the option for uploading your 10th-grade marksheet.
            {' '}
          </Typography>
          <Typography component="li" variant="body1">
            Upload the scan-copy of your 10th-grade marksheet in PDF Format.
          </Typography>
        </ul>,
      },
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Step 4b: Upload 12th Grade Marksheet (optional)
          </Typography>
        ),
        description: <ul>
          <Typography component="li" variant="body1">
            Similarly, for 12th Certificate enter the details required
          </Typography>
          <Typography component="li" variant="body1">
            Select the file containing your 12th-grade marks and upload it.
          </Typography>
        </ul>,
      },
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Step 4c: Upload Degree (Provisional/Final Degree Certificate and All Semester Marksheets.)
          </Typography>
        ),
        description: <ul>
          <Typography component="li" variant="body1">
            Proceed to the section for uploading proof of your degree.
          </Typography>
          <Typography component="li" variant="body1">
            Upload a copy of your degree certificate and marksheet for all semesters.
          </Typography>
          <Typography component="li" variant="body1">
            If you have multiple degrees to add, find the option to “Add Another Degree”.
          </Typography>
        </ul>,
      },
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Step 5: Review Uploaded Documents
          </Typography>
        ),
        description: <ul>
          <Typography component="li" variant="body1">
            Ensure that all uploaded documents are accurate and legible.
          </Typography>
          <Typography component="li" variant="body1">
            Carefully review the information associated with each degree.
          </Typography>
        </ul>,
      },
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Step 6: Proceed to Payment
          </Typography>
        ),
        description: <ul>
          <Typography component="li" variant="body1">
            After successfully uploading all required documents, click the "Pay" button at the bottom, and use the payment method of your choice to complete the process.
          </Typography>
          <Typography component="li" variant="body1">
            The prices may vary from individual to individual as it depends on number of documents submitted as well as the graduating institute.
          </Typography>

        </ul>,
      },
    ];
    case 'GCE': return [
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Step 1: Login to your web-wallet
          </Typography>
        ),
        description: <div>
          <ul>
            <Typography component="li" variant="body1">
              Enter your mobile number to receive the OTP
            </Typography>
            <Typography component="li" variant="body1">
              Enter the OTP received on your mobile number via SMS or WhatsApp
            </Typography>
          </ul>
          {' '}
          <Typography variant="body1">
            Note - This can be any mobile number on which you want to receive future communications
          </Typography>
        </div>,
      },
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Step 2: Identity Verification - Aadhaar or Passport
          </Typography>
        ),
        description: <div>
          <Typography variant="body1">
            Note - If you are an Indian citizen then select aadhaar to verify yourself, incase you are a foreign national or your mobile number is not linked to your aadhaar then you can verify using passport
            {' '}
          </Typography>
          <ul>
            <Typography component="li" variant="body1">
              Enter your 12-digit Aadhaar Number to receive OTP on your Aadhaar registered phone number.
            </Typography>
            <Typography variant="body1">
              OR
              {' '}
            </Typography>
            <Typography component="li" variant="body1">
              Scan your passport’s Front Page using webcam/mobile phone
            </Typography>
            <Typography component="li" variant="body1">
              Once verified you will proceed to the next step
            </Typography>
          </ul>
        </div>,
      },
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Step 3: Validate your  "GCE Reference Number"
          </Typography>
        ),
        description: <ul>
          <Typography component="li" variant="body1">
            Click the "User Details" and input your "GCE Number".
          </Typography>
          <Typography component="li" variant="body1">
            You will receive a 6-digit One-time-password on email registered with GCE.
            {' '}
          </Typography>
          {' '}
          <Typography component="li" variant="body1">
            Enter the OTP to proceed
          </Typography>
        </ul>,
      },
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Step 4:  Upload your dcouments
          </Typography>

        ),
        description: <div>
          <Typography variant="body1">
            Note -
            {' '}
          </Typography>
          <ol>
            <Typography component="li" variant="body1">
              You need to complete at least one of the three education checks
              {' '}
              <b>
                1).10th   2).12th   3).Degree.
              </b>
            </Typography>
            <Typography component="li" variant="body1">
              The rest should be marked as skipped by clicking the verification and the clicking the skip button
              {' '}
            </Typography>
            <Typography component="li" variant="body1">
              Make sure that the uploaded documents are of good quality with the details clearly visible in order to process the verifications faster
            </Typography>
          </ol>
        </div>,
      },
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Step 4a:  Upload 10th Grade Marksheet (optional)
          </Typography>
        ),
        description: <ul>
          <Typography component="li" variant="body1">
            Enter the details required under the 10th-Certificate section.
            {' '}
          </Typography>
          <Typography component="li" variant="body1">
            Click on the option for uploading your 10th-grade marksheet.
            {' '}
          </Typography>
          <Typography component="li" variant="body1">
            Upload the scan-copy of your 10th-grade marksheet in PDF Format.
          </Typography>
        </ul>,
      },
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Step 4b: Upload 12th Grade Marksheet (optional)
          </Typography>
        ),
        description: <ul>
          <Typography component="li" variant="body1">
            Similarly, for 12th Certificate enter the details required
          </Typography>
          <Typography component="li" variant="body1">
            Select the file containing your 12th-grade marks and upload it.
          </Typography>
        </ul>,
      },
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Step 4c: Upload Degree (Provisional/Final Degree Certificate and All Semester Marksheets.)
          </Typography>
        ),
        description: <ul>
          <Typography component="li" variant="body1">
            Proceed to the section for uploading proof of your degree.
          </Typography>
          <Typography component="li" variant="body1">
            Upload a copy of your degree certificate and marksheet for all semesters.
          </Typography>
          <Typography component="li" variant="body1">
            If you have multiple degrees to add, find the option to “Add Another Degree”.
          </Typography>
        </ul>,
      },
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Step 5: Review Uploaded Documents
          </Typography>
        ),
        description: <ul>
          <Typography component="li" variant="body1">
            Ensure that all uploaded documents are accurate and legible.
          </Typography>
          <Typography component="li" variant="body1">
            Carefully review the information associated with each degree.
          </Typography>
        </ul>,
      },
      {
        label: (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Step 6: Proceed to Payment
          </Typography>
        ),
        description: <ul>
          <Typography component="li" variant="body1">
            After successfully uploading all required documents, click the "Pay" button at the bottom, and use the payment method of your choice to complete the process.
          </Typography>
          <Typography component="li" variant="body1">
            The prices may vary from individual to individual as it depends on number of documents submitted as well as the graduating institute.
          </Typography>

        </ul>,
      },
    ];

    default: return [];
  }
};

export const configs = {
  'faq': (fontColor = '#fff') => customConfigFaq(process.env.REACT_APP_ORG, fontColor),
  'instructions': customConfigInstructions(process.env.REACT_APP_ORG),
};
