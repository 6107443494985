/* eslint-disable */
import { InfoOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, CircularProgress, Dialog, Grid, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import SimpleBackground from '../../Components/Background/Simple';
import { pathnameToText } from '../../Components/Header/data';
import { skipCredInTxn } from '../../Config/api';
import { UIStore, fetchFromStore, updateStore } from '../../Config/store';
import { camelCaseToString, getFetchProcessInterval, triggerCancellationController, updateDocumentTitle } from '../../Helper/helpers';
import { mixpanel, trackingEvents } from '../../Monitoring/Mixpanel';
import { getCategories, statesEnum, typesOfForm } from '../../Utils/enums';
import Digilocker, { openDigilocker } from '../DocResponse/digilocker';
import Scan from '../DocResponse/scan';
import Liveness from '../Liveness';
import Standalone from './standalone';

const eduDocInstructions = [
  'Please ensure that the scans are clear, without any shadows, smudges, or distortions, and that the registration/roll number is clearly visible.',
  'If you are uploading marksheets, merge all of them into a single PDF file and arrange them in chronological order.'
];

export default function DocRequest() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [showAuthOptions, setShowAuthOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingYes, setLoadingYes] = useState(false);
  const [selectedModeOfVerification, setSelectedModeOfVerification] = useState(null);
  const form = UIStore.useState((s) => s.form);
  const docData = UIStore.useState((s) => s.docData) ?? {};
  const currentWorkingStepperStep = UIStore.useState((s) => s.currentWorkingStepperStep);
  const credsWithDigilocker = UIStore.useState((s) => s.credsWithDigilocker) ?? {};
  const searchedQuery = UIStore.useState((s) => s.searchedQuery);
  const formUnderProcess = UIStore.useState((s) => s.formUnderProcess);
  const formInfo = UIStore.useState((s) => s.formInfo);
  const currentCredentialUnderFetch = form?.list?.[currentWorkingStepperStep];
  const [openSkipDialog, setOpenSkipDialog] = useState(null);
  const [skipWarning, setSkipWarning] = useState(null);
  const [loadingSkip, setLoadingSkip] = useState(false);
  const [openEduDocsDisclaimerDialog, setOpenEduDocsDisclaimerDialog] = useState(false);


  useEffect(() => {
    updateDocumentTitle([state?.edit ? 'Edit - ' : '', currentCredentialUnderFetch?.name, pathnameToText(location.pathname, searchedQuery?.link)]);

  }, [state])

  const openThisFlow = (key = currentWorkingStepperStep) => {
    setSelectedModeOfVerification(false);
    const options = {
      digilocker: [],
      others: []
    };
    form?.list?.[key]?.data?.authOptions?.
      forEach((x) => {
        if (x?.type === typesOfForm.DIGILOCKER) {
          options.digilocker.push(x);
        } else {
          options.others.push(x);
        }
      });

    console.log('options.others:', options);
    if (!currentCredentialUnderFetch) {
      return null
    }
    if (options.digilocker?.length > 0) {
      updateStore('credsWithDigilocker', { [key]: options.digilocker[0] });
      setShowAuthOptions(false);
      return null;
    }
    // if (options.others?.length === 1) {
    //   setSelectedModeOfVerification(options.others[0]);
    //   // setScreen(options.others[0]?.type)
    // }
    else if (currentCredentialUnderFetch && !options.others || options.others?.length === 0) {
      window.snackbar('warning', `No method found to verify ${currentCredentialUnderFetch?.name}, please contact ${form.orgName}`);
      navigate(-1);
      // setScreen(options.others[0]?.type)
    } else if (options.others?.length === 1) {
      // window.snackbar('warning', `No method found to verify ${currentCredentialUnderFetch?.name}, please contact ${form.orgName}`);
      // navigate(-1);
      setSelectedModeOfVerification(options.others[0]);
      // setScreen(options.others[0]?.type)
    } else {
      setShowAuthOptions(options.others);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (state?.openDigilocker) {
      setShowAuthOptions(!state?.openDigilocker);
    }
    else if (state?.openThisFlow) {
      openThisFlow();
    } else {
      const currentWorkingStepperStep2 = fetchFromStore('currentWorkingStepperStep');

      if (form?.list?.[currentWorkingStepperStep2]) {
        const temp = form?.list?.[currentWorkingStepperStep2]?.data?.authOptions;
        // ?.filter((x) => x?.type !== typesOfForm.DIGILOCKER);

        // if (temp?.length === 1) {
        //   setSelectedModeOfVerification(temp[0]);
        //   // setScreen(temp[0]?.type)
        // }
        // else
        console.log('temp:2', temp);
        if (temp?.length === 1) {
          setSelectedModeOfVerification(temp[0]);
        } else if (temp?.length > 1) {
          setShowAuthOptions(temp);
        } else {
          window.snackbar('warning', `No method found to verify ${form?.list?.[currentWorkingStepperStep2]?.name}, please contact ${form.orgName}`);
          navigate(-1);
        }
      }

    }
    setLoading(false);
  }, [state?.openDigilocker]);

  useEffect(() => {
    if (currentCredentialUnderFetch?.category?.name === 'Education' &&
      (
        formInfo[currentCredentialUnderFetch?.key].state === statesEnum.NOT_INITIALIZED ||
        formInfo[currentCredentialUnderFetch?.key].state === statesEnum.DATA_SAVED)) setOpenEduDocsDisclaimerDialog(true);
  }, [currentCredentialUnderFetch]);

  const screens = {
    'digilocker': () => <Digilocker selectedModeOfVerification={selectedModeOfVerification} />,
    'driving-license': () => <Digilocker />,
    'pan': () => <Digilocker />,
    'ocr': () => {
      return <Scan skip={() => skipTrigger([currentWorkingStepperStep])} />;
    },
    'passport': () => {
      return navigate('/checkin/scan');
    },
    'liveness': () => <Liveness />,
    'standalone': () => <Standalone
      edit={state?.edit}
      data={selectedModeOfVerification}
      skip={() => skipTrigger([currentWorkingStepperStep])} />,
  };

  useEffect(() => {

    mixpanel.track(trackingEvents.form.events.select.verificationStarted, {
      category: currentCredentialUnderFetch?.category?.name,
      name: currentCredentialUnderFetch?.name,
      status: 'STARTED'
    });
    clearInterval(getFetchProcessInterval());
    triggerCancellationController();

  }, []);

  useEffect(() => {
    if (!currentCredentialUnderFetch?.key) {
      navigate((typeof searchedQuery === 'object' && searchedQuery?.link) ? searchedQuery?.link : '/')
    }
  }, [searchedQuery, currentCredentialUnderFetch]);

  const skipTrigger = (ar) => {
    let total = 0
    const totalVerifications = formUnderProcess?.[searchedQuery?.txnId]?.sortedList;
    const skips = Object.keys(formInfo)?.reduce((acc, key) => {
      if (formInfo[key]?.skipAllowed) {
        acc.skippables.push(form?.list?.[key]?.name);
      }
      if (formInfo[key]?.markedAsSkip) {
        acc.skipped.push(key);
      }
      return acc;
    }, { skippables: [], skipped: [] })
    if (totalVerifications?.length === (skips.skipped?.length + 1)) {
      setSkipWarning(skips.skippables?.join(', '))
    } else {
      setSkipWarning(null)
    }
    // const condition=Object.keys(formInfo).every(key =>  key.skipAllowed && key.markedAsSkip)
    setOpenSkipDialog(ar);
  };
  const navigateToForm = () => {
    const url = new URL(document.URL);
    const path = `${url.origin}${searchedQuery?.link}`;
    // window.location.replace(path);
    window.location.href = path;
  };

  const skipThese = (ar) => {
    // navigate('/docs', { replace: true, state: { openDigilocker: false } });
    let maxIndex = -1;
    const myPos = formUnderProcess?.[searchedQuery?.txnId]?.sortedList?.findIndex((s) => {
      return ar.includes(s);
    });

    for (let i = myPos; i < formUnderProcess?.[searchedQuery?.txnId]?.sortedList?.length; i++) {
      if (!form?.list?.[formUnderProcess?.[searchedQuery?.txnId]?.sortedList?.[i]]?.existInWallet) {
        maxIndex = i - 1;
        break;
      }
    }

    console.log('one:', ar, maxIndex);
    if ((maxIndex !== -1) && (maxIndex + 1 < formUnderProcess?.[searchedQuery?.txnId]?.sortedList?.length)) {
      const one = formUnderProcess?.[searchedQuery?.txnId]?.sortedList[maxIndex + 1];
      const temp = form?.list?.[one]?.data?.authOptions;
      console.log('boohoo1:', one, temp);
      if (!temp || temp?.length === 0) {
        window.snackbar('warning', `There is no other way to fetch ${form?.list?.[one].name}`);
        return skipThese([...ar, one]);
        // navigate(-1);
        // setScreen(temp[0]?.type)
      } else {
        updateStore('currentWorkingStepperStep', one);
        openThisFlow(one);
      }
    } else {
      navigate(-1);
    }
  };

  return <SimpleBackground
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '100%',
      padding: '0 1em',
      margin: 0
    }}
    title={getCategories(currentCredentialUnderFetch?.category?.name)?.subtitle}
    backButtonOnClick={(callback) => {
      if (state?.edit) {
        return navigate('/docs', { state: { ...state, edit: false } });
      } else {
        callback()
      }
    }}>
    {loading ? <CircularProgress /> : ((selectedModeOfVerification) ?
      screens[selectedModeOfVerification?.type]() :
      (currentCredentialUnderFetch ?
        (currentCredentialUnderFetch?.data?.verificationType === typesOfForm.LIVENESS ?
          screens['liveness']() :
          ((!showAuthOptions) ?
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                padding: '1em',
                borderRadius: '8px',
                margin: '1em 0 0em 0',
              }}>
              {/* <Grid
              item
              xs={2}
              sm={2}
              sx={{
                marginRight: '1em', padding: '4px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              {digilocker ? <img
                src={digilocker} style={{
                  width: '100px',
                  height: '100px', padding: '1em',
                  borderRadius: '50%',
                  backgroundColor: '#F6F3FF',
                  objectFit: 'contain'
                }} alt="org" /> : <Skeleton variant="circular" sx={{ width: '100px', height: '100px' }} />}
            </Grid> */}
              <Grid
                item
                xs
                sm
                sx={{
                  width: '100%'
                }}>
                <Typography variant="body1" sx={{ fontWeight: 500, margin: '1em 0' }}>
                  Do you have Digilocker for verification of
                  {' '}
                  {Object.keys(credsWithDigilocker)?.map((j) => form?.list?.[j].name).join(', ')}
                  ?
                </Typography>


                <LoadingButton
                  loading={loadingYes}
                  variant="contained"
                  sx={{ fontWeight: 600, margin: '1em 0' }}
                  onClick={() => {
                    if (!state?.openDigilocker) {
                      const temp = currentCredentialUnderFetch?.data?.authOptions.find((x) => x?.type === typesOfForm.DIGILOCKER);
                      setSelectedModeOfVerification(temp);
                    } else {
                      setLoadingYes(true);
                      openDigilocker();
                    }
                    // setScreen('digilocker')
                  }}>
                  Yes
                </LoadingButton>

                <Button
                  variant="contained"
                  sx={{ fontWeight: 600, margin: '1em 0' }}
                  onClick={() => {
                    const one = currentWorkingStepperStep;
                    const temp = form?.list?.[one]?.data?.authOptions?.
                      filter((x) => x?.type !== typesOfForm.DIGILOCKER);
                    updateStore('currentWorkingStepperStep', one);
                    console.log('boohoo1:', one, temp);
                    // if (temp?.length === 1) {
                    //   setSelectedModeOfVerification(temp[0]);
                    //   // setScreen(temp[0]?.type)
                    // } else
                    if (temp?.length === 0) {
                      window.snackbar('warning', `There is no other way to fetch ${form?.list?.[one].name}`);
                      navigate(-1);
                      // setScreen(temp[0]?.type)
                    } else {
                      setShowAuthOptions(temp);
                    }
                    // setSelectedModeOfVerification(temp)
                  }}>
                  No
                </Button>
                {/* <Button
                variant="text"
                sx={{ fontWeight: 600, margin: '1em auto', width: '100%' }}
                onClick={() => {
                  .track(trackingEvents.form.events.select.verificationStarted, {

                  }).track(trackingEvents.kycForm.actions.response
                      .events.credentialSkipped(Object.keys(credsWithDigilocker)
                          ?.map((j) => form?.list?.[j].name).join(', ')));

                  skipThese(Object.keys(credsWithDigilocker));
                }}>
                Skip
              </Button> */}
              </Grid>
            </Grid> : (currentCredentialUnderFetch?.data?.authOptions ?
              (<Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                sx={{
                  padding: '1em',
                  borderRadius: '8px',
                  margin: '1em 0 0em 0',
                }}>
                {/* <Grid
                item
                xs={2}
                sm={2}
                sx={{
                  marginRight: '1em', padding: '4px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                {currentCredentialUnderFetch?.icon ? <img
                  src={currentCredentialUnderFetch?.icon} style={{
                    width: '100px',
                    height: '100px', padding: '1em',
                    borderRadius: '50%',
                    backgroundColor: '#F6F3FF',
                    objectFit: 'contain'
                  }} alt="org" /> : <Skeleton variant="circular" sx={{ width: '100px', height: '100px' }} />}
              </Grid> */}
                <Grid
                  item
                  xs
                  sm
                  sx={{
                    width: '100%'
                  }}>
                  <Typography variant="body1" sx={{ fontWeight: 500, margin: '1em 0' }}>
                    Please select a mode of verification for
                    {' '}
                    {currentCredentialUnderFetch?.data?.name}
                    ?
                  </Typography>

                  {showAuthOptions &&
                    showAuthOptions?.map((item, i) => (item.disableBuildCredential &&
                      showAuthOptions?.length > 1) ? null : <Button
                        key={`button${item}-${i}`}
                        variant="contained"
                        sx={{ fontWeight: 600, margin: '1em 0' }}
                        onClick={() => {
                          setSelectedModeOfVerification(item);
                        }}>
                      {item.name || camelCaseToString(item?.type || item.org)}
                    </Button>)
                  }
                  {/* <Button
                  variant="text"
                  sx={{ fontWeight: 600, margin: '1em auto', width: '100%' }}
                  onClick={() => {
                    // .track(trackingEvents.form.events.select.verificationStarted, {

                    }).track(trackingEvents.kycForm.actions.response
                    //     .events.credentialSkipped(Object.keys(credsWithDigilocker)
                    //         ?.map((j) => form?.list?.[j].name).join(', ')));

                    skipThese([currentWorkingStepperStep]);
                  }}>
                  Skip
                </Button> */}
                </Grid>
              </Grid>) :
              <Navigate to={-1} />))) :
        ((formUnderProcess?.[searchedQuery?.txnId]?.sortedList
          ?.findIndex((x) => x === currentWorkingStepperStep) + 1) >= form.shared?.length ?
          <Navigate to={searchedQuery?.link} /> : <Navigate to={-1} />)))
    }

    <Dialog
      maxWidth="xs"
      fullWidth
      open={openSkipDialog}
      onClose={() => { setOpenSkipDialog(null); }}
      sx={{ padding: '1em' }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ padding: '1em' }}>

        <Typography
          variant="body1"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
          }}>
          <InfoOutlined color="primary" sx={{ fontSize: 40, marginBottom: '0.4em' }} />
          {skipWarning ? <span>{'Please complete atleast 1 of the following: '}
            <br />
            <b>{skipWarning}</b></span>
            : 'I declare that I would like to skip the verification for ' +
            currentCredentialUnderFetch?.name}
        </Typography>

        {skipWarning ? <Button
          variant="outlined"
          color="brand"
          loading={loadingSkip}
          sx={{ width: '100%' }}
          onClick={async () => {
            setOpenSkipDialog(false);
            setTimeout(() => {
              setSkipWarning(null)
            }, 400);
          }}>
          Okay
        </Button> : <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: '1em' }}>
          <Button
            variant="outlined"
            color="brand"
            loading={loadingSkip}
            sx={{ width: '48%' }}
            onClick={async () => {
              setOpenSkipDialog(false);
            }}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="brand"
            loading={loadingSkip}
            sx={{ width: '48%' }}
            datacy="submit"
            onClick={async () => {
              setLoadingSkip(true);
              await skipCredInTxn(searchedQuery?.orgId, searchedQuery?.txnId, currentCredentialUnderFetch?.key)
                .then((zz) => {
                  if (zz) {
                    skipThese(openSkipDialog);
                    setLoadingSkip(false);
                    setOpenSkipDialog(false);
                    navigate(searchedQuery?.link);
                    // window.history.back();
                    // navigateToForm();
                  }
                });
              setLoadingSkip(false);
              setOpenSkipDialog(false);
            }}>
            Submit
          </LoadingButton>
        </Grid>}

      </Grid>
    </Dialog>

    <Dialog
      maxWidth="xs"
      fullWidth
      open={openEduDocsDisclaimerDialog}
      onClose={() => { setOpenEduDocsDisclaimerDialog(false); }}
      sx={{ padding: '1em' }}
    >
      <Grid
        container
        sx={{ padding: { xs: '1em', xl: '1.8em' }, display: 'grid', placeItems: 'center', rowGap: '1em' }}>
        <Grid sx={{ display: 'flex', columnGap: '1rem', alignItems: 'center', justifyContent: 'flex-start', mt: '1.5em', width: '100%', pl: '1.5em' }}>
          <Typography
            variant='h1'
            sx={{
              display: 'flex', columnGap: '1rem',
              alignItems: 'center', justifyContent: 'flex-start'
            }}
          >
            <InfoOutlined />
            Instructions
          </Typography>
        </Grid>
        <ul style={{ all: 'unset', marginLeft: '3em', display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'center', marginRight: '1em' }}>
          {
            eduDocInstructions?.map((row) =>
              <li>
                <Typography
                  variant="body1"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'left'
                  }}>
                  {row}
                </Typography>
              </li>)
          }
        </ul>
        <Button
          datacy="proceed-instructions"
          onClick={() => setOpenEduDocsDisclaimerDialog(false)}
          variant='outlined'
          sx={{ width: '90%', mb: '.8em', mt: '.8em' }}
        >
          Proceed
        </Button>
      </Grid>
    </Dialog>
    <Toolbar />
  </SimpleBackground>;
}
