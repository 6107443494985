import { AccountCircle, House, Logout, Notifications, Payments, PlayArrow } from '@mui/icons-material';

import newIcon from '../../Assets/new.png';
import { logout, updateStore } from '../../Config/store';
import { isDesktop } from '../../Helper/hooks';
import { mixpanel, trackingEvents } from '../../Monitoring/Mixpanel';
import { links } from '../../Utils/enums';

export const pathnameToText = (str) => {
  switch (str.split('/')[1]) {
    case 'login': return 'Sign up / Login';
    case 'notifications': return 'Notifications';
    case 'profile': return 'Profile';
    case `${links.EKYC}`: return 'Verify Documents';
    case 'docs': return 'Verify Documents';
    case 'submitted': return 'Verify Documents';
    case 'digilocker': return 'Verify Documents';
    case 'displayInfo': return `Verify Document`;
    case 'user-input': return `Input User Details`;
    case 'transaction-history': return `Transaction History Details`;
    case 'payment': return `Payment Details`;
    case 'insufficiency': return `Document Details`;
    case 'info': return `Info`;
    case 'profile-form': return `Profile Form`;
    case 'under-process': return 'Under Process';
    case 'terms': return 'Terms and Conditions';
    case 'privacy-policy': return 'Privacy Policy';
    case 'choose-payment-provider': return 'Payment Details';
    case 'choose-payment-region': return 'Payment Details';
    case 'form-payments': return 'Payment Details';
    // case '': return `Hi ${fetchFromStore('currentUserData')?.name || ''}`;
    default: return ' ';
  }
};

export const pathnameToBackLink = (str, specificLink = '') => {
  switch (str.split('/')[1]) {
    case 'submitted': return null;
    case 'login': return null;
    case 'docs': return specificLink;
    case 'choose-payment-provider': return specificLink;
    case 'choose-payment-region': return specificLink;
    // case 'docs': return specificLink;
    case 'form-payments': return specificLink;
    // case 'profile': return null;
    // case '': return `Hi ${fetchFromStore('currentUserData')?.name || ''}`;
    default: return -1;
  }
};

const setLastBaseUrlVisited = (url = '/') => {
  sessionStorage.setItem('lastBaseUrlVisited', url);
};

export const linksData = {
  // 'Credential Store': {
  //   onClick: (navigate) => {
  //     navigate('/credential-store');
  //   },
  //   icon: <CredentialStore />
  // },
  // 'Share History': {
  //   onClick: (navigate) => {
  //     navigate('/share-history');
  //   },
  //   icon: <ShareHistory />
  // },
  'Home': {
    onClick: (navigate) => {
      setLastBaseUrlVisited('/');
      navigate('/');
    },
    link: '/',
    icon: (props) => <House color={props} />
  }, 'Profile': {
    onClick: (navigate) => {
      setLastBaseUrlVisited('/profile');
      navigate('/profile');
    },
    link: '/profile',
    icon: (props) => <AccountCircle color={props} />
  },
  ...(!isDesktop() ? {
    'Notifications': {
      onClick: (navigate) => {
        setLastBaseUrlVisited('/notifications');
        navigate('/notifications');
      },
      link: '/notifications',
      icon: (props) => <Notifications color={props} />
    }
  } : {}),
  'Transaction History': {
    onClick: (navigate) => {
      setLastBaseUrlVisited('/transaction-history');
      navigate('/transaction-history');
    },
    link: '/transaction-history',
    icon: (props) => <Payments color={props} />
  },
  ...(process.env.REACT_APP_WES_AUTH ? {
    'FAQs / Instructions': {
      onClick: (navigate) => {
        setLastBaseUrlVisited('/instructions');
        navigate('/instructions');
      },
      link: '/instructions',
      icon: (props) => <Payments color={props} />
    },
    'Watch Demo': {
      onClick: (navigate) => {
        // navigate('/transaction-history');
        updateStore('openDemo', true);
      },
      link: '',
      icon: (props) => <PlayArrow color={props} />,
      secondaryAction: (<img src={newIcon} style={{ width: '24px' }} />)
    },
  } : {}),

  // 'Export to HRMS': {
  //   onClick: (navigate) => {
  //     // navigate('/transaction-history');
  //   },
  //   link: '',
  //   subtitle: 'Coming Soon...',
  //   icon: (props) => <IosShare color={props} />
  // },
  'Log out': {
    onClick: (navigate) => {
      mixpanel.track(trackingEvents.auth.events.sign.out);
      logout(false);
      // navigate('/login');
    },
    link: '/login',
    icon: (props) => <Logout color={props} />
  },
};
