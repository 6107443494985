/* eslint-disable */
import { ChevronRight } from '@mui/icons-material';
import { Avatar, Box, Card, Chip, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Skeleton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import SimpleBackground from '../../Components/Background/Simple';
import { getTxHistory } from '../../Config/payment/api';
import { UIStore } from '../../Config/store';
import { displayTime } from '../../Helper/dates';
import { paymentProvider, states } from '../../Utils/enums';

const transactionHistory = () => {

  const navigate = useNavigate()

  const searchedQuery = UIStore.useState((s) => s.searchedQuery) ?? {};
  const [data, setData] = React.useState([])
  const [isThereAnyPayment, setIsThereAnyPayment] = useState(false)
  const [loading, setLoading] = useState(true)
  const CURRENCY_SYMBOL = data?.currency ?? ""

  const flattenHistory = (payload) => {
    let paymentList = []
    payload?.map((root) => {
      return root?.paymentList?.length > 0 && root?.paymentList?.map((child) => {
        if (child?.refundDetails?.length > 0) {
          child?.refundDetails?.map(refund => {
            const obj = {
              org: root?.org,
              transactionId: root?.transactionId,
              ...child,
              amount: refund?.amount,
              currencySymbol: child?.price?.currencySymbol,
              currentStatus: child?.currentStatus,
              totalAmountPaid: child?.price?.toPay
            }
            paymentList.push(obj)
          })
        }
        else {
          const obj = {
            org: root?.org,
            transactionId: root?.transactionId,
            ...child,
            amount: child?.price?.toPay,
            currencySymbol: child?.price?.currencySymbol,
            currentStatus: child?.currentStatus,
            totalAmountPaid: child?.price?.toPay
          }
          if (child?.price?.items?.length > 0) {
            paymentList.push(obj)
          }
        }
      })
    })
    console.log("pal", paymentList)
    return paymentList
  }

  React.useEffect(() => {
    setLoading(true)
    getTxHistory({
      orgId: searchedQuery?.orgId,
      txnId: searchedQuery?.txnId
    }).then(res => {
      if (res.data) {
        setIsThereAnyPayment(res.data?.some((x) => {
          return x?.paymentList?.length > 0
        }))
        setData(res.data)
      }
      setLoading(false)
    }).catch(err => {
      console.log("err", err)
      setLoading(false)
    })
  }, [])

  return <SimpleBackground
    sx={{
      // maxWidth: '400px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '100%',
      padding: '0',
      margin: 'auto',
    }}
    title={'Transaction History'}>
    <br />
    {loading ?
      [1, 2, 3, 4]?.map((item, j) => <Skeleton
        variant="rectangular" sx={{
          borderRadius: '6px',
          margin: '1em',
          width: '100%',
          height: '14rem',
        }} />) :
        isThereAnyPayment ?
        data?.map((item, j) => {
          return item?.paymentList?.length > 0 ?
            <Card sx={{ width: '100%', margin: '1em auto', paddingBottom: '0' }}
              key={'txHistory-' + j}>
              <Box>
                <Grid container
                  direction="row"
                  alignItems="center"
                  sx={{ padding: '1em' }}
                >
                  <Avatar sx={{ width: 40, height: 40 }} alt="" src={item?.org?.imageUrl} />
                  <Box>
                    <Typography variant="h5" sx={{ margin: '0 1em' }}>
                      {item?.form.name}
                    </Typography>
                    <Typography variant="body2" sx={{ margin: '0 1em' }}>
                      {item?.org?.displayName}
                    </Typography>


                  </Box>
                </Grid>
              </Box>
              <List dense sx={{ margin: 0, paddingBottom: 0 }}>
                {item?.paymentList?.map((i, j) => {

                  return <ListItemButton key={i?._id} divider={j !== item?.paymentList.length - 1}
                    variant="outlined"
                    onClick={() => navigate(`/payment?transactionHistory=true`, {
                      state: {
                        order: {
                          org: item?.org,
                          ...i
                        }
                      }
                    })}
                    sx={{ padding: 0 }}
                  >
                    <ListItem
                      secondaryAction={<Typography sx={{ fontWeight: 'bold', display: 'flex', flexDirection: 'row' }} >
                        {i?.price?.currencySymbol} {i?.amount}
                        <ChevronRight fontSize='medium' />
                      </Typography>
                      }
                    >
                      <ListItemAvatar sx={{ width: 25, height: 25 }}>
                        {paymentProvider[i?.provider]?.logo}
                      </ListItemAvatar>
                      <ListItemText
                        primary={displayTime(i?.updatedAt, 'DD-MMM-YYYY hh:mm:A')}
                        secondary={<Grid container>
                          <Chip
                            color={states[i?.currentStatus?.toUpperCase()]?.theme}
                            variant="outlined"
                            sx={{
                              '& .MuiChip-label': { fontSize: '1rem', fontWeight: 'bold' }
                            }}
                            size="small"
                            icon={states[i?.currentStatus?.toUpperCase()]?.icon}
                            label={states[i?.currentStatus?.toUpperCase()]?.title} />

                          {i?.refundDetails?.length > 0 ? <Chip
                            color={states[i?.currentStatus?.toUpperCase()]?.theme}
                            variant="outlined"
                            sx={{
                              marginLeft: '1em',
                              '& .MuiChip-label': { fontSize: '1rem', fontWeight: 'bold' }
                            }}
                            size="small"
                            label={`${i?.refundDetails?.length} refund${i?.refundDetails?.length > 1 ? 's' : ''}`} /> :
                            null}
                        </Grid>}
                      />
                    </ListItem>
                  </ListItemButton>

                })}
              </List>
            </Card> : null
        }) : <Grid container>
          <Typography variant="h6"
            sx={{ width: '100%' }}>
            No Transaction History Found
          </Typography>
        </Grid>}
  </SimpleBackground>;
};

export default transactionHistory;
